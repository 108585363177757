import {
    NEWSLETTER_READ_MEMBERS,
    NEWSLETTER_START_MEMBERS_RELOAD,
    NEWSLETTER_FINISHED_MEMBERS_RELOAD,
    NEWSLETTER_DELETE_MEMBER
} from '../../actions/types';

const initialState = {
    members: [],
    member: {},
    error: {},
    loading: false,
    readable: false,
};

export default (state = initialState, action) => {
    const {type, payload } = action;

    switch (type) {
        case NEWSLETTER_READ_MEMBERS:
            return {...state,members: payload.members, readable:true};
        case NEWSLETTER_START_MEMBERS_RELOAD:
            return {...state, loading: true};
        case NEWSLETTER_FINISHED_MEMBERS_RELOAD:
            return {...state, loading: false};
        case NEWSLETTER_DELETE_MEMBER:
            return {
                ...state,
               members:[ ...state.members.filter(member => member.id !== payload.member.id)]
            };
        default:
            return state;
    }

}
