import {
  SET_ALERT,
  REMOVE_ALERT,
  START_RELOAD,
  FINISHED_RELOAD,
  TOGGLE_SIDEBAR,
  SET_EDIT_MODE,
  UNSET_EDIT_MODE,
  SHOW_COVER_MODE,
  HIDE_COVER_MODE,
  SET_PROGRESS,
  UNSET_PROGRESS,
  SET_ACTIVE_LECTURE,
  SET_IS_MODEL,
  HIDE_SIDEBAR,
} from '../actions/types';

const initialState = {
  alert: [],
  loading: false,
  toggleSidebar: false,
  hideSidebar: false,
  editMode: false,
  coverMode: false,
  isModel: false,
  progress: 0,
  error: {},
  activeLecture: '',
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        alert: [payload],
      };
    case SET_IS_MODEL:
      return {
        ...state,
        isModel: payload,
      };

    case REMOVE_ALERT:
      return {
        ...state,
        alert: [],
      };
    case START_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        toggleSidebar: !state.toggleSidebar,
      };
    case HIDE_SIDEBAR:
      return {
        ...state,
        hideSidebar: !state.hideSidebar,
      };

    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case UNSET_PROGRESS:
      return {
        ...state,
        progress: 0,
      };
    case UNSET_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      };

    case SHOW_COVER_MODE:
      return {
        ...state,
        coverMode: true,
      };
    case HIDE_COVER_MODE:
      return {
        ...state,
        coverMode: false,
      };
    case SET_ACTIVE_LECTURE:
      return {
        ...state,
        activeLecture: payload,
      };
    default:
      return state;
  }
}
