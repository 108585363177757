import {
  READ_ALL_TICKETS,
  READ_TICKETS,
  READ_ONE_TICKET,
  CREATE_TICKET,
  UPDATE_TICKET,
  DELETE_TICKET,
  CLEAR_TICKET,
  START_TICKETS_RELOAD,
  FINISHED_TICKETS_RELOAD,
} from '../../actions/types';

const initialState = {
  tickets: [],
  all_tickets: [],
  ticketType: {},
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  error: {},
  loading: false,
  readable: false,
  all_readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ALL_TICKETS:
      return {
        ...state,
        all_tickets: [...payload.tickets],
        all_readable: true,
      };
    case READ_TICKETS:
      console.log({ payload: payload.tickets });
      return {
        ...state,
        tickets: [...payload.tickets],
        // pagination: {
        //   ...state.pagination,
        //   totalPages: payload.pagination.totalPages,
        //   perPage: payload.pagination.perPage,
        //   totalCount: payload.pagination.totalCount,
        // },
        readable: true,
      };
    case READ_ONE_TICKET:
      return {
        ...state,
        ticketType: { ...payload.ticketType },
      };
    case CREATE_TICKET:
      return {
        ...state,
        tickets: [payload, ...state.tickets],
      };
    case UPDATE_TICKET:
      return {
        ...state,
        tickets: [
          ...state.tickets.map((ticketType) =>
            ticketType.id === payload.ticketType.id
              ? payload.ticketType
              : ticketType
          ),
        ],
      };
    case DELETE_TICKET:
      return {
        ...state,
        tickets: [
          ...state.tickets.filter(
            (ticketType) => ticketType.id !== payload.ticketType.id
          ),
        ],
      };
    case CLEAR_TICKET:
      return {
        ...state,
        ticketType: {},
      };
    case START_TICKETS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_TICKETS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
