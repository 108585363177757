import {
  READ_SEASONS,
  READ_SINGLE_SEASON,
  CREATE_SEASON,
  UPDATE_SEASON,
  DELETE_SEASON,
  CLEAR_SEASON,
  // SEASONS_ERROR,
  START_SEASON_RELOAD,
  FINISHED_SEASON_RELOAD,
} from '../../types/tahkeem/seasons';

const initialState = {
  seasons: [],
  season: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SEASONS:
      return {
        ...state,
        seasons: [...payload.seasons],
        readable: true,
      };
    case READ_SINGLE_SEASON:
      return {
        ...state,
        season: { ...payload.season },
      };
    case CREATE_SEASON:
      return {
        ...state,
        seasons: [payload, ...state.seasons],
      };
    case UPDATE_SEASON:
      return {
        ...state,
        seasons: [
          ...state.seasons.map((season) =>
            season._id === payload.season._id ? payload.season : season
          ),
        ],
      };
    case DELETE_SEASON:
      return {
        ...state,
        seasons: [
          ...state.seasons.filter(
            (season) => season._id !== payload.season._id
          ),
        ],
      };
    case CLEAR_SEASON:
      return {
        ...state,
        season: {},
      };

    case START_SEASON_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SEASON_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
