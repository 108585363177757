import {
  READ_OPTIONS,
  UPDATE_OPTIONS,
  FLUSH_ALL_CACHE,
  OPTIONS_ERROR,
  CREATE_LOGO,
  MAIN_CERT,
  READ_LOGO,
  READ_TEMP_CERT,
  CREATE_PREVIEW_CERT,
  START_OPTIONS_RELOAD,
  FINISHED_OPTIONS_RELOAD,
} from './types';
import axios from 'axios';
import { readItemsAsync } from './equCurd/readItems';
import { readOneItemAsync } from './equCurd/readOneItem';
import { createItemAsync } from './equCurd/createItem';
import { updateItemAsync } from './equCurd/updateItem';

export const startOptionsReload = () => (dispatch) => {
  dispatch({ type: START_OPTIONS_RELOAD });
};

export const finishedOptionsReload = () => (dispatch) => {
  dispatch({ type: FINISHED_OPTIONS_RELOAD });
};

export const readPubOptions = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/options/public`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/options/public'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_OPTIONS_BACKEND_URL + '/public'
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/options/public',
    successType: READ_OPTIONS,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const readOptions = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/options/all`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/options/all'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_OPTIONS_BACKEND_URL + '/all'
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/options/all',
    successType: READ_OPTIONS,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const updateOptions = (formData) =>
  updateItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/options/`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/options/'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_OPTIONS_BACKEND_URL
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/options',
    successType: UPDATE_OPTIONS,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    formData,
    noReload: true,
    id: '',
    title: 'Options',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const flushAllCache = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/options/flushAllCache`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/options/flushAllCache'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_OPTIONS_BACKEND_URL + '/flushAllCache'
        : localStorage.getItem('REACT_APP_BACKEND_URL') +
          '/options/flushAllCache',
    successType: FLUSH_ALL_CACHE,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const createLogo = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/files/`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/files/'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_FILES_BACKEND_URL
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/files/',
    successType: CREATE_LOGO,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,

    noReload: true,
    formData,
    title: 'Course Image',
    contentType: 'multipart/form-data',
    type: 'file',
  });

export const readLogo = (id) =>
  readOneItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/files/`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/files/'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_FILES_BACKEND_URL
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/files/',
    successType: READ_LOGO,
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      // 'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
    id,
  });

export const readTempCertificate = (id) =>
  readOneItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/files/`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/files/'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_FILES_BACKEND_URL
        : localStorage.getItem('REACT_APP_BACKEND_URL') + '/files/',
    successType: READ_TEMP_CERT,
    errorType: OPTIONS_ERROR,
    contentType: 'multipart/form-data',
    xService: 'elearning',
    id,
  });

export const regenerateVariants = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `http://api.ha.ae/files/regenerate-variants`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/files/regenerate-variants'
        : window.location.hostname.includes('lecocq')
        ? `https://services.lecocqassociate.com/files/regenerate-variants`
        : localStorage.getItem('REACT_APP_BACKEND_URL') +
          `/files/regenerate-variants`,
    withAlert: true,
    successMsg: 'Request Regenerate variants has been sent successfully',
    successType: '',
    errorType: OPTIONS_ERROR,
    startReload: startOptionsReload,
    finishedReload: finishedOptionsReload,
    title: 'Media File',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const createPreviewCertificate = (formData) => {
  return async (dispatch) => {
    const onSuccess = (success) => {
      dispatch({ type: CREATE_PREVIEW_CERT, payload: success.data });
      return success;
    };
    const onError = (error) => {
      dispatch({ type: OPTIONS_ERROR });
      return error;
    };
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token'),
          'Access-Control-Allow-Origin': '*',
          'x-client':
            typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
              ? process.env.REACT_APP_CLIENT_ID
              : localStorage.getItem('REACT_APP_CLIENT_ID'),
        },
      };
      let url =
        window.location.hostname.includes('fablab') ||
        window.location.hostname.includes('admin.ha') ||
        window.location.hostname.includes('ha.admin')
          ? `https://api.ha.ae/e-leaning/`
          : window.location.hostname.includes('e-green')
          ? 'https://api.e-greenlearning.com/e-leaning/'
          : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_EL_BACKEND_URL
          : localStorage.getItem('REACT_APP_BACKEND_URL') + '/e-leaning/';
      const success = await axios.post(
        `${url}/certificates/preview`,
        formData,
        config
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};

export const mainCert = (data) => (dispatch) => {
  dispatch({
    type: MAIN_CERT,
    payload: data,
  });
};
