import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { connect } from 'react-redux';
import Spinner from '../components/spinner/Spinner';
import { hasSubscription } from '../helpers/hasSubscription';
import TestPage from '../pages/testPage/TestPage';

const General = lazy(() => import('../pages/options/general/General'));
const Email = lazy(() => import('../pages/options/email/Email'));
const LoginProviders = lazy(() =>
  import('../pages/options/login/LoginProviders')
);
const Cache = lazy(() => import('../pages/options/cache/Cache'));
const Certificates = lazy(() =>
  import('../pages/options/certificates/Certificates')
);
const Theme = lazy(() => import('../pages/options/theme/Theme'));
const TicketCertificates = lazy(() =>
  import('../pages/options/certificates/TicketCertificates')
);

const License = lazy(() => import('../pages/options/license/License'));

const LectureForm = lazy(() => import('../pages/lectures/LectureForm'));
const Lectures = lazy(() => import('../pages/lectures/Lectures'));
const Course = lazy(() => import('../pages/eLearning/courses/Courses'));
const EditCourse = lazy(() => import('../pages/eLearning/courses/EditCourse'));

const ETags = lazy(() => import('../pages/eLearning/tags/ETags'));
const ETagForm = lazy(() => import('../pages/eLearning/tags/ETagForm'));
const ECategories = lazy(() =>
  import('../pages/eLearning/categories/ECategories')
);
const ECategoryForm = lazy(() =>
  import('../pages/eLearning/categories/ECategoryForm')
);

const LoginForm = lazy(() => import('../pages/auth/LoginForm'));
const RegisterForm = lazy(() => import('../pages/auth/RegisterForm'));
const ForgotPasswordForm = lazy(() =>
  import('../pages/auth/ForgotPasswordForm')
);
const ResetPasswordForm = lazy(() => import('../pages/auth/ResetPasswordForm'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Roles = lazy(() => import('../pages/roles/Roles'));
const RoleForm = lazy(() => import('../pages/roles/RoleForm'));
const Capabilities = lazy(() => import('../pages/capabilities/Capabilities'));
const CapabilityForm = lazy(() =>
  import('../pages/capabilities/CapabilityForm')
);
const Users = lazy(() => import('../pages/users/Users'));
const UsersAbou = lazy(() => import('../pages/users/UsersAbou'));
const UserForm = lazy(() => import('../pages/users/UserForm'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const Courses = lazy(() => import('../pages/eLearning/courses/Courses'));
const Media = lazy(() => import('../pages/media/Media'));

const BCategories = lazy(() => import('../pages/blog/categories/BCategories'));
const BCategoryForm = lazy(() =>
  import('../pages/blog/categories/BCategoryForm')
);
const BTags = lazy(() => import('../pages/blog/tags/BTags'));
const BTagForm = lazy(() => import('../pages/blog/tags/BTagForm'));

const Path = lazy(() => import('../pages/eLearning/path/Path'));
const PathForm = lazy(() => import('../pages/eLearning/path/PathForm'));

const Pages = lazy(() => import('../pages/blog/pages/Pages'));
const Page = lazy(() => import('../pages/blog/pages/Page'));
const PageForm = lazy(() => import('../pages/blog/pages/PageForm'));

const Posts = lazy(() => import('../pages/blog/posts/Posts'));
const Post = lazy(() => import('../pages/blog/posts/Post'));

const PostForm = lazy(() => import('../pages/blog/posts/PostForm'));
const Menus = lazy(() => import('../pages/blog/menus/Menus'));
const MenuForm = lazy(() => import('../pages/blog/menus/MenuForm'));
const TicketTypes = lazy(() =>
  import('../pages/ticketing/ticketTypes/TicketTypes')
);
const TicketType = lazy(() =>
  import('../pages/ticketing/ticketTypes/TicketType')
);
const TicketTypeForm = lazy(() =>
  import('../pages/ticketing/ticketTypes/TicketTypeForm')
);
const TTags = lazy(() => import('../pages/ticketing/tags/TTags'));
const TTagForm = lazy(() => import('../pages/ticketing/tags/TTagForm'));

const WCategories = lazy(() =>
  import('../pages/ticketing/categories/TCategories')
);
const WCategoryForm = lazy(() =>
  import('../pages/ticketing/categories/TCategoryForm')
);

const Schemas = lazy(() => import('../pages/typing/Schemas/Schemas'));
const SchemaForm = lazy(() => import('../pages/typing/Schemas/SchemaForm'));

const NewSchemas = lazy(() => import('../pages/new-typing/Schemas/Schemas'));
const NewSchemaForm = lazy(() =>
  import('../pages/new-typing/Schemas/SchemaForm')
);

const CustomFields = lazy(() =>
  import('../pages/typing/customFields/CustomFields')
);
const CustomFieldForm = lazy(() =>
  import('../pages/typing/customFields/CustomFieldForm')
);
const Services = lazy(() => import('../pages/typing/Services/Services'));
const Service = lazy(() => import('../pages/typing/Services/Service'));
const ServiceForm = lazy(() => import('../pages/typing/Services/ServiceForm'));
const ArticleForm = lazy(() => import('../pages/typing/Services/ArticleForm'));
const Articles = lazy(() => import('../pages/typing/Services/Articles'));

const newsletterLists = lazy(() => import('../pages/newsletter/lists/Lists'));
const newsletterListForm = lazy(() =>
  import('../pages/newsletter/lists/ListForm')
);
const newsletterForms = lazy(() => import('../pages/newsletter/forms/Forms'));

const newsletterSignUpForm = lazy(() =>
  import('../pages/newsletter/forms/SignUpForm')
);
const newsletterOptions = lazy(() =>
  import('../pages/newsletter/options/Options')
);
const newsletterMembers = lazy(() =>
  import('../pages/newsletter/members/Members')
);

const newsletterCampaigns = lazy(() =>
  import('../pages/newsletter/campaigns/Campaign')
);

const newsletterCampaignForm = lazy(() =>
  import('../pages/newsletter/campaigns/CampaignForm')
);
const PaymentOptions = lazy(() => import('../pages/payment/PaymentOptions'));
const Reconciliation = lazy(() => import('../pages/payment/Reconciliation'));

const Surveys = lazy(() => import('../pages/surveys/Surveys'));
const SurveyForm = lazy(() => import('../pages/surveys/SurveyForm'));
const SurveyResult = lazy(() => import('../pages/surveys/SurveyResult'));

const TCtegories = lazy(() => import('../pages/tahkeem/categories/TCtegories'));
const TCategoryForm = lazy(() =>
  import('../pages/tahkeem/categories/TCategoryForm')
);
const TCtegoryInfo = lazy(() =>
  import('../pages/tahkeem/categories/TCtegoryInfo')
);

const TForms = lazy(() => import('../pages/tahkeem/forms/TForms'));
const TFormsForm = lazy(() => import('../pages/tahkeem/forms/TFormsForm'));

const Judgments = lazy(() => import('../pages/tahkeem/judgments/Judgments'));
const JudgmentForm = lazy(() =>
  import('../pages/tahkeem/judgments/JudgmentForm')
);

const Assessments = lazy(() =>
  import('../pages/tahkeem/assessments/Assessments')
);
const AssessmentForm = lazy(() =>
  import('../pages/tahkeem/assessments/AssessmentForm')
);

const Scenarios = lazy(() => import('../pages/tahkeem/scenarios/Scenarios'));
const ScenarioForm = lazy(() =>
  import('../pages/tahkeem/scenarios/ScenarioForm')
);

const Seasons = lazy(() => import('../pages/tahkeem/seasons/Seasons'));
const SeasonForm = lazy(() => import('../pages/tahkeem/seasons/SeasonForm'));

const Applications = lazy(() =>
  import('../pages/tahkeem/applications/Applications')
);
const ApplicationForm = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationForm')
);
const ApplicationResult = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationResult')
);
const ApplicationEvaluate = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationEvaluate')
);
const ApplicationEvaluateFull = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationEvaluateFull')
);
const ApplicationView = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationView')
);
const ApplicationInformation = lazy(() =>
  import('../pages/tahkeem/applications/ApplicationInformation')
);

const TCtegoriesTop = lazy(() =>
  import('../pages/tahkeem/categories/TCtegoriesTop')
);
const Cycles = lazy(() => import('../pages/tahkeem/cycles/Cycles'));
const CycleForm = lazy(() => import('../pages/tahkeem/cycles/CycleForm'));

const Routes = ({ history }) => {
  return (
    <Suspense
      fallback={
        <div className="relative flex flex-col bg-red-500 app">
          <Spinner />
        </div>
      }
    >
      <Switch>
        <ProtectedRoute
          history={history}
          exact
          path="/"
          component={Dashboard}
        />
        <Route exact path="/register" component={RegisterForm} />
        <Route exact path="/setup" component={RegisterForm} />
        <Route exact path="/login" component={LoginForm} />
        <Route exact path="/forgot-password" component={ForgotPasswordForm} />
        <Route
          exact
          path="/reset-password/:token"
          component={ResetPasswordForm}
        />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/roles" component={Roles} />
        <ProtectedRoute exact path="/roles/:id/edit" component={RoleForm} />
        <ProtectedRoute exact path="/roles/new" component={RoleForm} />
        <ProtectedRoute exact path="/capabilities" component={Capabilities} />
        <ProtectedRoute
          exact
          path="/capabilities/:id/edit"
          component={CapabilityForm}
        />
        <ProtectedRoute
          exact
          path="/capabilities/new"
          component={CapabilityForm}
        />
        <ProtectedRoute exact path="/test" component={TestPage} />
        <ProtectedRoute exact path="/users" component={Users} />
        <ProtectedRoute exact path="/users-dhabi" component={UsersAbou} />
        <ProtectedRoute exact path="/users/:id/edit" component={UserForm} />
        <ProtectedRoute exact path="/users/new" component={UserForm} />
        <ProtectedRoute exact path="/media" component={Media} />
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists"
            component={newsletterLists}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists/new"
            component={newsletterListForm}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists/:id/edit"
            component={newsletterListForm}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists/:listId/forms"
            component={newsletterForms}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists/:listId/forms/new"
            component={newsletterSignUpForm}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/lists/:listId/forms/:id/edit"
            component={newsletterSignUpForm}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path="/newsletter/options"
            component={newsletterOptions}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path={'/newsletter/lists/:listId/forms/:formId/members'}
            component={newsletterMembers}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path={'/newsletter/campaigns'}
            component={newsletterCampaigns}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path={'/newsletter/campaigns/new'}
            component={newsletterCampaignForm}
          />
        )}
        {hasSubscription('newsletter') && (
          <ProtectedRoute
            exact
            path={'/newsletter/campaigns/:id/edit'}
            component={newsletterCampaignForm}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/path" component={Path} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/path/new" component={PathForm} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/path/:id/edit" component={PathForm} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/courses" component={Courses} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/course" component={Course} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/courses/new" component={EditCourse} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/courses/:id/edit"
            component={EditCourse}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/lectures" component={Lectures} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/lectures/new" component={LectureForm} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/lectures/:id/edit"
            component={LectureForm}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/eLearning/categories"
            component={ECategories}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/eLearning/categories/new"
            component={ECategoryForm}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/eLearning/categories/:id/edit"
            component={ECategoryForm}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute exact path="/eLearning/tags" component={ETags} />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/eLearning/tags/new"
            component={ETagForm}
          />
        )}
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/eLearning/tags/:id/edit"
            component={ETagForm}
          />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/pages" component={Pages} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/page/:id" component={Page} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/pages/new" component={PageForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/pages/:id/edit" component={PageForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/posts" component={Posts} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/post/:id" component={Post} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/posts/new" component={PostForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/posts/:id/edit" component={PostForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute
            exact
            path="/blog/categories"
            component={BCategories}
          />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute
            exact
            path="/blog/categories/new"
            component={BCategoryForm}
          />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute
            exact
            path="/blog/categories/:id/edit"
            component={BCategoryForm}
          />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/blog/tags" component={BTags} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/blog/tags/new" component={BTagForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute
            exact
            path="/blog/tags/:id/edit"
            component={BTagForm}
          />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/menus" component={Menus} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/menu/new" component={MenuForm} />
        )}
        {hasSubscription('blog') && (
          <ProtectedRoute exact path="/menu/:id/edit" component={MenuForm} />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute exact path="/ticket-types" component={TicketTypes} />
        )}

        {hasSubscription('ticketing') && (
          <ProtectedRoute exact path="/ticket/tags" component={TTags} />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute exact path="/ticket/tags/new" component={TTagForm} />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket/tags/:id/edit"
            component={TTagForm}
          />
        )}

        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket/categories"
            component={WCategories}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket/categories/new"
            component={WCategoryForm}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket/categories/:id/edit"
            component={WCategoryForm}
          />
        )}

        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-con-workshop"
            component={TicketTypes}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-el-workshop"
            component={TicketTypes}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute exact path="/tickets" component={TicketTypes} />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-conferences"
            component={TicketTypes}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/services-types"
            component={TicketTypes}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-types/new"
            component={TicketTypeForm}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-types/:id/edit"
            component={TicketTypeForm}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/ticket-type/:id"
            component={TicketType}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/schemas/" component={Schemas} />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/schemas/new" component={SchemaForm} />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/schemas/:id/edit"
            component={SchemaForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/new-schemas/" component={NewSchemas} />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/new-schemas/new"
            component={NewSchemaForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/new-schemas/:id/edit"
            component={NewSchemaForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/custom-fields/"
            component={CustomFields}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/custom-fields/new"
            component={CustomFieldForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/custom-fields/:id/edit"
            component={CustomFieldForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/services/:name" component={Services} />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/services/:name/new"
            component={ServiceForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/services/:name/:id/edit"
            component={ServiceForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/articles/:name" component={Articles} />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/articles/:name/new"
            component={ArticleForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute
            exact
            path="/articles/:name/:id/edit"
            component={ArticleForm}
          />
        )}
        {hasSubscription('types') && (
          <ProtectedRoute exact path="/service/:name/:id" component={Service} />
        )}
        {hasSubscription('payment') && (
          <ProtectedRoute
            exact
            path="/payment/options"
            component={PaymentOptions}
          />
        )}
        {hasSubscription('payment') && (
          <ProtectedRoute
            exact
            path="/payment/reconciliation"
            component={Reconciliation}
          />
        )}
        {/* Categories Start */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/awards"
            component={TCtegoriesTop}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/award/:id/edit"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/award/new"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/categories"
            component={TCtegories}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/:catId/categories"
            component={TCtegories}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/:catId/info"
            component={TCtegoryInfo}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/categories/new"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/categories/:id/edit"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/categories/new/:catId"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/categories/:id/edit/:catId"
            component={TCategoryForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/judgments/new/:catId"
            component={JudgmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/judgments/:id/edit/:catId"
            component={JudgmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/assessments/new/:catId"
            component={AssessmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/assessments/:id/edit/:catId"
            component={AssessmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/forms/new/:catId"
            component={TFormsForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/forms/:id/edit/:catId"
            component={TFormsForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/scenarios/new/:catId"
            component={ScenarioForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/scenarios/:id/edit/:catId"
            component={ScenarioForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/:catId/applications"
            component={Applications}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/:catId/seasons"
            component={Seasons}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/seasons/new/:catId"
            component={SeasonForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/seasons/:id/edit/:catId"
            component={SeasonForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/:catId/cycles"
            component={Cycles}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/cycles/new/:catId"
            component={CycleForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/cycles/:id/edit/:catId"
            component={CycleForm}
          />
        )}
        {/* Categories End */}
        {/* Judgment Start */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/judgments"
            component={Judgments}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/judgments/new"
            component={JudgmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/judgments/:id/edit"
            component={JudgmentForm}
          />
        )}
        {/* Judgment End */}
        {/* Assessment Start */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/assessments"
            component={Assessments}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/assessments/new"
            component={AssessmentForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/assessments/:id/edit"
            component={AssessmentForm}
          />
        )}
        {/* Scenario Start */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/scenarios"
            component={Scenarios}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/scenarios/new"
            component={ScenarioForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/scenarios/:id/edit"
            component={ScenarioForm}
          />
        )}
        {/* Scenario End */}
        {/* Season Start */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute exact path="/tahkeem/seasons" component={Seasons} />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/seasons/new"
            component={SeasonForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/seasons/:id/edit"
            component={SeasonForm}
          />
        )}
        {/* Season End */}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute exact path="/tahkeem/forms" component={TForms} />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/forms/new"
            component={TFormsForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/forms/:id/edit"
            component={TFormsForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications"
            component={Applications}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/edit"
            component={ApplicationForm}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/result"
            component={ApplicationResult}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/evaluate"
            component={ApplicationEvaluate}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/evaluate-full"
            component={ApplicationEvaluateFull}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/view"
            component={ApplicationView}
          />
        )}
        {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/applications/:id/info"
            component={ApplicationInformation}
          />
        )}
        {/* {hasSubscription('tahkeem') && (
          <ProtectedRoute
            exact
            path="/tahkeem/forms/:id/edit"
            component={TFormsForm}
          />
        )} */}
        {hasSubscription('survey') && (
          <ProtectedRoute exact path="/surveys" component={Surveys} />
        )}
        {hasSubscription('survey') && (
          <ProtectedRoute exact path="/surveys/new" component={SurveyForm} />
        )}
        {hasSubscription('survey') && (
          <ProtectedRoute
            exact
            path="/surveys/:id/edit"
            component={SurveyForm}
          />
        )}
        {hasSubscription('survey') && (
          <ProtectedRoute
            exact
            path="/surveys/:id/result"
            component={SurveyResult}
          />
        )}
        <ProtectedRoute exact path="/options/general" component={General} />
        <ProtectedRoute exact path="/options/email" component={Email} />
        <ProtectedRoute exact path="/options/license" component={License} />
        {hasSubscription('el') && (
          <ProtectedRoute
            exact
            path="/options/certificates"
            component={Certificates}
          />
        )}
        {hasSubscription('ticketing') && (
          <ProtectedRoute
            exact
            path="/options/ticket_certificates"
            component={TicketCertificates}
          />
        )}
        <ProtectedRoute
          exact
          path="/options/login-providers"
          component={LoginProviders}
        />
        <ProtectedRoute exact path="/options/theme" component={Theme} />
        <ProtectedRoute
          exact
          path="/options/flush-all-cache"
          component={Cache}
        />
      </Switch>
    </Suspense>
  );
};

// export default MP(Routes);
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, null)(Routes);
