import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './routes';
import { loadUser, readSubscriptions } from './actions/auth';
import { readPubOptions, readOptions } from './actions/options';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { readSchemas } from './actions/typing/schemas';
import { readServices } from './actions/typing/services';
import { readRoles } from './actions/roles';
//sss
const history = createBrowserHistory();
function App({
  loadUser,
  readSchemas,
  readPubOptions,
  readSubscriptions,
  readServices,
  readRoles,
  authReducer: { isAuthenticated, loading },
}) {
  console.log({
    REACT_APP_SUBSCRIPTIONS_URL: process.env.REACT_APP_SUBSCRIPTIONS_URL,
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    REACT_APP_OPTIONS_BACKEND_URL: process.env.REACT_APP_OPTIONS_BACKEND_URL,
    REACT_APP_FILES_BACKEND_URL: process.env.REACT_APP_FILES_BACKEND_URL,
    REACT_APP_USERS_BACKEND_URL: process.env.REACT_APP_USERS_BACKEND_URL,
    REACT_APP_ROLES_BACKEND_URL: process.env.REACT_APP_ROLES_BACKEND_URL,
    REACT_APP_CAPABILITIES_BACKEND_URL:
      process.env.REACT_APP_CAPABILITIES_BACKEND_URL,
    REACT_APP_EL_BACKEND_URL: process.env.REACT_APP_EL_BACKEND_URL,
    REACT_APP_BLOG_BACKEND_URL: process.env.REACT_APP_BLOG_BACKEND_URL,
    REACT_APP_TYPES_BACKEND_URL: process.env.REACT_APP_TYPES_BACKEND_URL,
    REACT_APP_TICKETING_BACKEND_URL:
      process.env.REACT_APP_TICKETING_BACKEND_URL,
    REACT_APP_PAYMENT_BACKEND_URL: process.env.REACT_APP_PAYMENT_BACKEND_URL,
    REACT_APP_NEWSLETTER_BACKEND_URL:
      process.env.REACT_APP_NEWSLETTER_BACKEND_URL,
    REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    REACT_APP_SERVICES: process.env.REACT_APP_SERVICES,
  });

  // fetch('http://services.larsa.io/types/schemas/all', {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'x-client': '5e723f2ad6e73554f2ca8be6',
  //   },
  // })
  //   .then((result) => {
  //     console.log({ result });
  //   })
  //   .catch((err) => {});

  useEffect(() => {
    if (window.location.hostname.includes('fablab')) {
      localStorage.setItem('REACT_APP_BACKEND_URL', 'https://api.ha.ae');
      localStorage.setItem('REACT_APP_CLIENT_ID', '5ee0d81bd6e73554f2ca8bed');
      localStorage.setItem(
        'REACT_APP_SERVICES',
        JSON.stringify(['blog', 'ticketing', 'types'])
      );
    }

    if (
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
    ) {
      localStorage.setItem('REACT_APP_BACKEND_URL', 'https://api.ha.ae');
      localStorage.setItem('REACT_APP_CLIENT_ID', '5ec25ccad6e73554f2ca8bec');
      localStorage.setItem('REACT_APP_SERVICES', JSON.stringify(['types']));
    }
    if (window.location.hostname.includes('lecocq')) {
      localStorage.setItem(
        'REACT_APP_BACKEND_URL',
        'https://services.lecocqassociate.com'
      );
      localStorage.setItem('REACT_APP_CLIENT_ID', '5f9821438d00210019d33809');
      localStorage.setItem(
        'REACT_APP_SERVICES',
        JSON.stringify(['blog', 'types'])
      );
    }

    if (
      typeof process.env.REACT_APP_CLIENT_ID === 'undefined' &&
      localStorage.getItem('REACT_APP_CLIENT_ID') === null
    ) {
      readSubscriptions()
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {});
    }

    if (
      (typeof process.env.REACT_APP_CLIENT_ID !== 'undefined' ||
        localStorage.getItem('REACT_APP_CLIENT_ID') !== null) &&
      localStorage.getItem('token') === null
    ) {
      readPubOptions();
    }
    if (
      (typeof process.env.REACT_APP_CLIENT_ID !== 'undefined' ||
        localStorage.getItem('REACT_APP_CLIENT_ID') !== null) &&
      localStorage.getItem('token') !== null
    ) {
      loadUser()
        .then((result) => {
          readOptions();
          readRoles();
          // readSchemas();
          // readServices()
          //   .then((result) => {
          //     console.log(result);
          //   })
          //   .catch((err) => {});

          if (result.status !== 200) {
            // history.push('/login');
            localStorage.removeItem('token');
            console.log('1111111111');
          }
        })
        .catch((err) => {
          // history.push('/login');
          localStorage.removeItem('token');
          console.log('22222222');
        });
    } else {
      // history.push('/login');
      console.log('33333333333');
    }
    // readSchemas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={history}>
      <Helmet>
        <style>
          {!isAuthenticated || loading
            ? '#root,body { background-color: #fafafa; }'
            : `#root,body { background-color: ${
                window.location.hostname.includes('lecocq')
                  ? '#fafafa'
                  : '#0c64f7'
              }`}
        </style>
      </Helmet>
      <Route history={history} component={Routes} />
    </Router>
  );
}

const mapStateToProps = (state) => ({
  appReducer: state.appReducer,
  authReducer: state.authReducer,
  optionsReducer: state.optionsReducer,
});

export default connect(mapStateToProps, {
  loadUser,
  readPubOptions,
  readSubscriptions,
  readRoles,
  readSchemas,
  readServices,
})(App);
