import {
  READ_FILES,
  READ_ALL_FILES,
  READ_ONE_FILE,
  CREATE_FILE,
  DELETE_FILE,
  SET_FILES_CURRENT_PAGE,
  START_FILES_RELOAD,
  FINISHED_FILES_RELOAD,
  SET_FILE_TYPE,
} from '../actions/types';

const initialState = {
  files: [],
  allFiles: [],
  fileType: 'images',
  filesCurrentPage: 1,
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  file: {},
  error: {},
  loading: false,
  readable: false,
};
// const imagesF = new RegExp(/.(gif|jpe?g|tiff|png|webp|bmp)$/i);
// const videosF = new RegExp(
//   /.(mp4|mpg|mpeg|ogg|wmv|mov|avi|mpeg-2|flv|mpeg-4)$/i
// );
export default function file(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_FILES:
      return {
        ...state,
        files: [...payload.files],
        pagination: {
          ...state.pagination,
          totalPages: payload.pagination.totalPages,
          perPage: payload.pagination.perPage,
          totalCount: payload.pagination.totalCount,
        },
        readable: true,
      };
    case READ_ALL_FILES:
      return {
        ...state,
        allFiles: [...payload.files],
      };
    case READ_ONE_FILE:
      return {
        ...state,
        file: { ...payload },
      };
    case CREATE_FILE:
      return {
        ...state,
        file: { ...payload },

        files: [{ ...payload }, ...state.files],
      };

    case DELETE_FILE:
      return {
        ...state,
        files: [...state.files.filter((file) => file._id !== payload.file._id)],
        allFiles: [
          ...state.allFiles.filter((file) => file._id !== payload.file._id),
        ],
      };

    case SET_FILES_CURRENT_PAGE:
      return {
        ...state,
        filesCurrentPage: payload,
      };
    case START_FILES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_FILES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case SET_FILE_TYPE:
      return {
        ...state,
        fileType: payload,
      };

    default:
      return state;
  }
}
