import {
  READ_ROLES,
  READ_ONE_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  SEARCH_ROLE_FILTER,
  ROLE_ERROR,
  CLEAR_ROLE,
  START_ROLES_RELOAD,
  FINISHED_ROLES_RELOAD,
} from './types';

import { readItemsAsync } from './equCurd/readItems';
import { createItemAsync } from './equCurd/createItem';
import { updateItemAsync } from './equCurd/updateItem';
import { deleteItemAsync } from './equCurd/deleteItem';
import { readOneItemAsync } from './equCurd/readOneItem';

export const startRolesReload = () => (dispatch) => {
  dispatch({ type: START_ROLES_RELOAD });
};

export const finishedRolesReload = () => (dispatch) => {
  dispatch({ type: FINISHED_ROLES_RELOAD });
};

export const readRoles = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/roles/all`
        : window.location.hostname.includes('e-green')
        ? `https://api.e-greenlearning.com/auth/roles/all`
        : 'https://services.larsa.io/auth/roles/all',

    successType: READ_ROLES,
    errorType: ROLE_ERROR,
    startReload: startRolesReload,
    finishedReload: finishedRolesReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const readOneRole = (id) =>
  readOneItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/roles/`
        : window.location.hostname.includes('e-green')
        ? `https://api.e-greenlearning.com/auth/roles/`
        : 'https://services.larsa.io/auth/roles/',

    successType: READ_ONE_ROLE,
    errorType: ROLE_ERROR,
    startReload: startRolesReload,
    finishedReload: finishedRolesReload,
    id,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const createRole = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/roles/`
        : window.location.hostname.includes('e-green')
        ? `https://api.e-greenlearning.com/auth/roles/`
        : 'https://services.larsa.io/auth/roles/',

    successType: CREATE_ROLE,
    errorType: ROLE_ERROR,
    startReload: startRolesReload,
    finishedReload: finishedRolesReload,
    formData,
    title: 'Role',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const updateRole = (formData) =>
  updateItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/roles/`
        : window.location.hostname.includes('e-green')
        ? `https://api.e-greenlearning.com/auth/roles/`
        : 'http://services.larsa.io/auth/roles/',

    successType: UPDATE_ROLE,
    errorType: ROLE_ERROR,
    startReload: startRolesReload,
    finishedReload: finishedRolesReload,
    formData,
    id: formData.id,
    title: 'Role',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const deleteRole = (id) =>
  deleteItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/roles/`
        : window.location.hostname.includes('e-green')
        ? `https://api.e-greenlearning.com/auth/roles/`
        : 'https://services.larsa.io/auth/roles/',

    successType: DELETE_ROLE,
    errorType: ROLE_ERROR,
    startReload: startRolesReload,
    finishedReload: finishedRolesReload,
    id,
    title: 'Role',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const searchRoleFilter = (text) => (dispatch) => {
  dispatch({
    type: SEARCH_ROLE_FILTER,
    payload: text,
  });
};

export const clearRole = () => (dispatch) => {
  dispatch({ type: CLEAR_ROLE });
};
