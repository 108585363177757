import {
  READ_ASSESSMENTS,
  READ_ASSESSMENTS_TREE,
  READ_SINGLE_ASSESSMENT,
  CREATE_ASSESSMENT,
  UPDATE_ASSESSMENT,
  DELETE_ASSESSMENT,
  CLEAR_ASSESSMENT,
  // ASSESSMENTS_ERROR,
  START_ASSESSMENT_RELOAD,
  FINISHED_ASSESSMENT_RELOAD,
} from '../../types/tahkeem/assessments';
const initialState = {
  assessments: [],
  assessmentsTree: [],
  assessment: {},
  error: {},
  loading: false,
  readable: false,
};

const addData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [{ ...payload, children: [] }, ...d.children],
        }
      : { ...d, children: addData(d.children, payload) }
  );
  return data;
};

const deleteData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [...d.children.filter((f) => f._id !== payload._id)],
        }
      : { ...d, children: deleteData(d.children, payload) }
  );
  return data;
};

const updateData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [
            ...d.children.map((f) =>
              f._id === payload._id
                ? { ...payload, children: [...f.children] }
                : f
            ),
          ],
        }
      : { ...d, children: deleteData(d.children, payload) }
  );
  return data;
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ASSESSMENTS:
      return {
        ...state,
        assessments: [...payload.assessments],
        readable: true,
      };
    case READ_ASSESSMENTS_TREE:
      return {
        ...state,
        assessmentsTree: [...payload.assessments],
        readable: true,
      };
    case READ_SINGLE_ASSESSMENT:
      return {
        ...state,
        assessment: { ...payload.assessment },
      };
    case CREATE_ASSESSMENT:
      return {
        ...state,
        assessments: [payload.assessment, ...state.assessments],
        assessmentsTree:
          payload.assessment.parent === null
            ? [
                { ...payload.assessment, children: [] },
                ...state.assessmentsTree,
              ]
            : addData(state.assessmentsTree, payload.assessment),
      };
    case UPDATE_ASSESSMENT:
      return {
        ...state,
        assessments: [
          ...state.assessments.map((assessment) =>
            assessment._id === payload.assessment._id
              ? payload.assessment
              : assessment
          ),
        ],
        assessmentsTree:
          payload.assessment.parent === null
            ? [
                ...state.assessmentsTree.map((assessment) =>
                  assessment._id === payload.assessment._id
                    ? {
                        ...payload.assessment,
                        children: [...assessment.children],
                      }
                    : assessment
                ),
              ]
            : updateData(state.assessmentsTree, payload.assessment),
      };
    case DELETE_ASSESSMENT:
      return {
        ...state,
        assessments: [
          ...state.assessments.filter(
            (assessment) => assessment._id !== payload.assessment._id
          ),
        ],
        assessmentsTree:
          payload.assessment.parent === null
            ? [
                ...state.assessmentsTree.filter(
                  (assessment) => assessment._id !== payload.assessment._id
                ),
              ]
            : deleteData(state.assessmentsTree, payload.assessment),
      };
    case CLEAR_ASSESSMENT:
      return {
        ...state,
        assessment: {},
      };

    case START_ASSESSMENT_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_ASSESSMENT_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
