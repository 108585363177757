import React, { Fragment, useEffect } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';

import Sidebar from '../components/layout/Sidebar';
import Alert from '../components/layout/Alert';
import Navbar from '../components/layout/Navbar';
import Spinner from '../components/spinner/Spinner';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  component: Component,
  appReducer,
  isAuthenticated,
  loading,
  location,
  history,
  ...rest
}) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isAuthenticated) {
  //       history.push('/login');
  //     }
  //   }, 2000);
  // }, [isAuthenticated, history]);
  return (
    <Route
      {...rest}
      render={props =>
        loading && !isAuthenticated ? (
          <div className="relative flex flex-col app bg-gray-p">
            <Spinner />
          </div>
        ) : !loading && !isAuthenticated ? (
          <Redirect to="/login" />
        ) : (
          isAuthenticated &&
          !loading && (
            <Fragment>
              <div className="relative flex flex-col app bg-gray-p font-body">
                <Navbar />

                <Sidebar />
                <Alert />
                <main
                  className={`container mx-auto flex justify-center min-h-screen mt-6 items-start ${
                    appReducer.hideSidebar ? 'pl-0I' : 'pl-64'
                  }`}>
                  <div className="w-full px-12">
                    <Component {...props} {...rest} />
                  </div>
                </main>
              </div>
            </Fragment>
          )
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  loading: state.authReducer.loading,
  isAuthenticated: state.authReducer.isAuthenticated,
  appReducer: state.appReducer,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
