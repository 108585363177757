export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_JUDGMENT_RELOAD = 'START_JUDGMENT_RELOAD';
export const FINISHED_JUDGMENT_RELOAD = 'FINISHED_JUDGMENT_RELOAD';

export const READ_JUDGMENTS = 'READ_JUDGMENTS';
export const READ_JUDGMENTS_TREE = 'READ_JUDGMENTS_TREE';
export const READ_SINGLE_JUDGMENT = 'READ_SINGLE_JUDGMENT';
export const CREATE_JUDGMENT = 'CREATE_JUDGMENT';
export const UPDATE_JUDGMENT = 'UPDATE_JUDGMENT';
export const DELETE_JUDGMENT = 'DELETE_JUDGMENT';
export const CLEAR_JUDGMENT = 'CLEAR_JUDGMENT';
export const JUDGMENTS_ERROR = 'JUDGMENTS_ERROR';
