import {
  NEWSLETTER_READ_LISTS,
  NEWSLETTER_READ_ONE_LIST,
  NEWSLETTER_CREATE_LIST,
  NEWSLETTER_UPDATE_LIST,
  NEWSLETTER_DELETE_LIST,
  NEWSLETTER_CLEAR_LIST,
  NEWSLETTER_START_LISTS_RELOAD,
  NEWSLETTER_FINISHED_LISTS_RELOAD,
} from '../../actions/types';

const initialState = {
  lists: [],
  list: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEWSLETTER_READ_LISTS:
      return {
        ...state,
        lists: [...payload.lists],
        readable: true,
      };
    case NEWSLETTER_READ_ONE_LIST:
      return {
        ...state,
        list: { ...payload.list },
        readable: true,
      };
    case NEWSLETTER_CREATE_LIST:
      return {
        ...state,
        lists: [payload, ...state.lists],
      };
    case NEWSLETTER_UPDATE_LIST:
      return {
        ...state,
        lists: [
          ...state.lists.map((list) =>
            list.id === payload.list.id ? payload.list : list
          ),
        ],
      };
    case NEWSLETTER_DELETE_LIST:
      return {
        ...state,
        lists: [...state.lists.filter((list) => list.id !== payload.list.id)],
      };
    case NEWSLETTER_CLEAR_LIST:
      return {
        ...state,
        list: {},
      };
    case NEWSLETTER_START_LISTS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case NEWSLETTER_FINISHED_LISTS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
