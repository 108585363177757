import {
  READ_E_CATEGORIES,
  READ_ONE_E_CATEGORY,
  CREATE_E_CATEGORY,
  UPDATE_E_CATEGORY,
  DELETE_E_CATEGORY,
  CLEAR_E_CATEGORY,
  START_E_CATEGORIES_RELOAD,
  FINISHED_E_CATEGORIES_RELOAD,
} from '../../actions/types';

const initialState = {
  categories: [],
  category: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_E_CATEGORIES:
      return {
        ...state,
        categories: [...payload.categories],
        readable: true,
      };
    case READ_ONE_E_CATEGORY:
      return {
        ...state,
        category: { ...payload.category },
      };
    case CREATE_E_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories],
      };
    case UPDATE_E_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.map((category) =>
            category.id === payload.category.id ? payload.category : category
          ),
        ],
      };
    case DELETE_E_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (category) => category.id !== payload.category.id
          ),
        ],
      };
    case CLEAR_E_CATEGORY:
      return {
        ...state,
        category: {},
      };
    case START_E_CATEGORIES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_E_CATEGORIES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
