import {
  READ_T_TAGS,
  READ_ONE_T_TAG,
  CREATE_T_TAG,
  UPDATE_T_TAG,
  DELETE_T_TAG,
  CLEAR_T_TAG,
  START_T_TAGS_RELOAD,
  FINISHED_T_TAGS_RELOAD,
} from '../../actions/types';

const initialState = {
  tags: [],
  tag: {},
  error: {},
  loading: false,
  readable: false,
};

export default function tags(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_T_TAGS:
      return {
        ...state,
        tags: [...payload.tags],
        readable: true,
      };
    case READ_ONE_T_TAG:
      return {
        ...state,
        tag: { ...payload.tag },
      };
    case CREATE_T_TAG:
      return {
        ...state,
        tags: [payload, ...state.tags],
      };
    case UPDATE_T_TAG:
      return {
        ...state,
        tags: [
          ...state.tags.map(tag =>
            tag.id === payload.tag.id ? payload.tag : tag
          ),
        ],
      };
    case DELETE_T_TAG:
      return {
        ...state,
        tags: [...state.tags.filter(tag => tag.id !== payload.tag.id)],
      };
    case CLEAR_T_TAG:
      return {
        ...state,
        tag: {},
      };
    case START_T_TAGS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_T_TAGS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
