import React from 'react';
import { NavLink } from 'react-router-dom';
import ACL from '../../acl/ACL';

const ServicePart = ({
  allowedRoles,
  serviceName,
  relativeName,
  service,
  url,
  serviceTitle,
  setState,
  state,
  mainIcon,
  items,
  onClick,
}) => {
  return service ? (
    typeof process.env.REACT_APP_SERVICES !== 'undefined' ? (
      process.env.REACT_APP_SERVICES.includes(serviceName)
    ) : (
      JSON.parse(localStorage.getItem('REACT_APP_SERVICES')).includes(
        serviceName
      ) && (
        <ACL allowedRoles={allowedRoles} renderNoAccess={() => <></>}>
          <div
            className={`flex justify-start items-center mt-2 px-5 w-full p-2  ${
              window.location.hostname.includes('lecocq')
                ? 'hover:bg-gray-200 text-gray-700'
                : 'hover:bg-blue-lar-normal text-gray-50'
            } rounded-l-full cursor-pointer relative ${
              (relativeName
              ? state.includes(relativeName)
              : state.includes(serviceName))
                ? window.location.hostname.includes('lecocq')
                  ? 'bg-gray-100'
                  : 'bg-blue-lar-dark'
                : ''
            }`}
            onClick={() => {
              onClick
                ? onClick()
                : state.includes(serviceName)
                ? setState(state.filter((s) => s !== serviceName))
                : setState([...state, serviceName]);
            }}
          >
            {mainIcon}
            <p
              className={`mx-5 my-2 text-sm ${
                (relativeName
                ? state.includes(relativeName)
                : state.includes(serviceName))
                  ? 'font-bold'
                  : 'font-noramal'
              }`}
            >
              {serviceTitle}
            </p>
          </div>

          {(relativeName
            ? state.includes(relativeName)
            : state.includes(serviceName)) &&
            items.length > 0 && (
              <div className="p-1">
                <div
                  className={`${
                    window.location.hostname.includes('lecocq')
                      ? 'bg-gray-200'
                      : 'bg-blue-lar-dark'
                  } rounded-md px-3 w-full p-2 cursor-pointer overflow-x-hidden overflow-y-auto`}
                  style={{ maxHeight: '16rem', direction: 'rtl' }}
                >
                  {items.map((item) => (
                    <ACL
                      allowedRoles={
                        item.allowedRoles ? item.allowedRoles : allowedRoles
                      }
                      renderNoAccess={() => <></>}
                    >
                      <NavLink
                        to={item.url}
                        className={`flex justify-start items-center mt-1 w-full p-2 ${
                          window.location.hostname.includes('lecocq')
                            ? 'text-gray-700'
                            : 'text-gray-50'
                        }`}
                        style={{ direction: 'ltr' }}
                      >
                        {item.icon}
                        <p className="mx-5 my-2 text-sm capitalize">
                          {item.name}
                        </p>
                      </NavLink>
                    </ACL>
                  ))}
                </div>
              </div>
            )}
        </ACL>
      )
    )
  ) : (
    <ACL allowedRoles={allowedRoles} renderNoAccess={() => <></>}>
      <NavLink
        to={url}
        className={`flex justify-start items-center mt-2 px-5 w-full p-2 ${
          window.location.hostname.includes('lecocq')
            ? 'hover:bg-gray-200 text-gray-700'
            : 'hover:bg-blue-lar-dark text-gray-50'
        } rounded-l-full  cursor-pointer relative`}
        onClick={() => {
          onClick
            ? onClick()
            : state.includes(serviceName)
            ? setState(state.filter((s) => s !== serviceName))
            : setState([...state, serviceName]);
        }}
      >
        {mainIcon} <p className="mx-5 my-2 text-sm">{serviceTitle}</p>
      </NavLink>{' '}
    </ACL>
  );
};

export default ServicePart;
