export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_ASSESSMENT_RELOAD = 'START_ASSESSMENT_RELOAD';
export const FINISHED_ASSESSMENT_RELOAD = 'FINISHED_ASSESSMENT_RELOAD';

export const READ_ASSESSMENTS = 'READ_ASSESSMENTS';
export const READ_ASSESSMENTS_TREE = 'READ_ASSESSMENTS_TREE';

export const READ_SINGLE_ASSESSMENT = 'READ_SINGLE_ASSESSMENT';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';
export const CLEAR_ASSESSMENT = 'CLEAR_ASSESSMENT';
export const ASSESSMENTS_ERROR = 'ASSESSMENTS_ERROR';
