import {
  READ_B_TAGS,
  READ_ONE_B_TAG,
  CREATE_B_TAG,
  UPDATE_B_TAG,
  DELETE_B_TAG,
  CLEAR_B_TAG,
  START_B_TAGS_RELOAD,
  FINISHED_B_TAGS_RELOAD,
} from '../../actions/types';

const initialState = {
  tags: [],
  tag: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_B_TAGS:
      return {
        ...state,
        tags: [...payload.tags],
        readable: true,
      };
    case READ_ONE_B_TAG:
      return {
        ...state,
        tag: { ...payload.tag },
      };
    case CREATE_B_TAG:
      return {
        ...state,
        tags: [payload, ...state.tags],
      };
    case UPDATE_B_TAG:
      return {
        ...state,
        tags: [
          ...state.tags.map((tag) =>
            tag.id === payload.tag.id ? payload.tag : tag
          ),
        ],
      };
    case DELETE_B_TAG:
      return {
        ...state,
        tags: [...state.tags.filter((tag) => tag.id !== payload.tag.id)],
      };
    case CLEAR_B_TAG:
      return {
        ...state,
        tag: {},
      };
    case START_B_TAGS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_B_TAGS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
