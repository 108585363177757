import {
  READ_T_CATEGORIES,
  READ_T_CATEGORIES_TREE,
  READ_SINGLE_T_CATEGORY,
  CREATE_T_CATEGORY,
  READ_T_CATEGORIES_MAIN,
  UPDATE_T_CATEGORY,
  DELETE_T_CATEGORY,
  CLEAR_T_CATEGORY,
  // T_CATEGORIES_ERROR,
  START_T_CATEGORY_RELOAD,
  FINISHED_T_CATEGORY_RELOAD,
} from '../../types/tahkeem/categories';

const initialState = {
  categories: [],
  categoriesTree: [],
  categoriesMain: [],

  category: {},
  error: {},
  loading: false,
  readable: false,
};

const addData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [{ ...payload, children: [] }, ...d.children],
        }
      : { ...d, children: addData(d.children, payload) }
  );
  return data;
};

const deleteData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [...d.children.filter((f) => f._id !== payload._id)],
        }
      : { ...d, children: deleteData(d.children, payload) }
  );
  return data;
};

const updateData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [
            ...d.children.map((f) =>
              f._id === payload._id
                ? { ...payload, children: [...f.children] }
                : f
            ),
          ],
        }
      : { ...d, children: updateData(d.children, payload) }
  );
  return data;
};

export default function tCategories(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_T_CATEGORIES:
      return {
        ...state,
        categories: [...payload.categories],
        readable: true,
      };
    case READ_T_CATEGORIES_TREE:
      return {
        ...state,
        categoriesTree: [...payload.categories],
        readable: true,
      };
    case READ_T_CATEGORIES_MAIN:
      return {
        ...state,
        categoriesMain: [...payload.categories],
        readable: true,
      };

    case READ_SINGLE_T_CATEGORY:
      return {
        ...state,
        category: { ...payload.category },
      };
    case CREATE_T_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories],
        categoriesTree:
          payload.parent === null
            ? [...state.categoriesTree, { ...payload, children: [] }]
            : addData(state.categoriesTree, payload),
      };
    case UPDATE_T_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.map((category) =>
            category._id === payload.category._id ? payload.category : category
          ),
        ],
        categoriesTree:
          payload.category.parent === null
            ? [
                ...state.categoriesTree.map((category) =>
                  category._id === payload.category._id
                    ? { ...payload.category, children: [...category.children] }
                    : category
                ),
              ]
            : updateData(state.categoriesTree, payload.category),
      };
    case DELETE_T_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (category) => category._id !== payload.category._id
          ),
        ],
        categoriesTree:
          payload.category.parent === null
            ? [
                ...state.categoriesTree.filter(
                  (category) => category._id !== payload.category._id
                ),
              ]
            : deleteData(state.categoriesTree, payload.category),
      };
    case CLEAR_T_CATEGORY:
      return {
        ...state,
        category: {},
      };

    case START_T_CATEGORY_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_T_CATEGORY_RELOAD:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
