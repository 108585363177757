import {
  READ_CAPABILITIES,
  READ_ONE_CAPABILITY,
  CREATE_CAPABILITY,
  UPDATE_CAPABILITY,
  DELETE_CAPABILITY,
  CLEAR_CAPABILITY,
  START_CAPABILITIES_RELOAD,
  FINISHED_CAPABILITIES_RELOAD,
} from '../actions/types';

const initialState = {
  capabilities: [],
  capability: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_CAPABILITIES:
      return {
        ...state,
        capabilities: [...payload.capabilities],
        readable: true,
      };
    case READ_ONE_CAPABILITY:
      return {
        ...state,
        capability: { ...payload.capability },
      };
    case CREATE_CAPABILITY:
      return {
        ...state,
        capabilities: [payload, ...state.capabilities],
      };
    case UPDATE_CAPABILITY:
      return {
        ...state,
        capabilities: [
          ...state.capabilities.map((capability) =>
            capability.id === payload.capability.id
              ? payload.capability
              : capability
          ),
        ],
      };
    case DELETE_CAPABILITY:
      return {
        ...state,
        capabilities: [
          ...state.capabilities.filter(
            (capability) => capability.id !== payload.capability.id
          ),
        ],
      };
    case CLEAR_CAPABILITY:
      return {
        ...state,
        capability: {},
      };
    case START_CAPABILITIES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_CAPABILITIES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
