import {
  READ_SERVICES,
  READ_ONE_SERVICE,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  SERVICE_ERROR,
  CLEAR_SERVICE,
  START_SERVICES_RELOAD,
  FINISHED_SERVICES_RELOAD,
  READ_SERVICES_WITHOUT_REDUX,
} from '../types';

import { readItemsAsync } from '../equCurd/readItems';
import { readOneItemAsync } from '../equCurd/readOneItem';
import { createItemAsync } from '../equCurd/createItem';
import { updateItemAsync } from '../equCurd/updateItem';
import { deleteItemAsync } from '../equCurd/deleteItem';

export const startServicesReload = () => (dispatch) => {
  dispatch({ type: START_SERVICES_RELOAD });
};

export const finishedServicesReload = () => (dispatch) => {
  dispatch({ type: FINISHED_SERVICES_RELOAD });
};

export const readServices = (name) =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}/all`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}/all`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}/all`,
    successType: READ_SERVICES,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const readServicesWithoutRedux = (name) =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}/all`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}/all`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}/all`,
    successType: READ_SERVICES_WITHOUT_REDUX,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const readOneService = (id, name) =>
  readOneItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}`,
    successType: READ_ONE_SERVICE,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    id,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const createService = (formData, name) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}`,
    successType: CREATE_SERVICE,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    formData,
    title: 'Service',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const updateService = (formData, name) =>
  updateItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}`,
    successType: UPDATE_SERVICE,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    formData,
    id: formData.id,
    title: 'Service',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const deleteService = (id, name) =>
  deleteItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/${name}`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/${name}`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/${name}`,
    successType: DELETE_SERVICE,
    errorType: SERVICE_ERROR,
    startReload: startServicesReload,
    finishedReload: finishedServicesReload,
    id: id,
    title: 'Service',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const clearService = () => (dispatch) => {
  dispatch({ type: CLEAR_SERVICE });
};
