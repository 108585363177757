import React, { useState, useEffect } from 'react';
import {
  RiPencilRuler2Line,
  RiBook2Line,
  RiMenuAddLine,
  RiAwardLine,
  RiSurveyFill,
  RiSurveyLine,
  RiSendPlane2Fill,
} from 'react-icons/ri';
import { BsImages } from 'react-icons/bs';

import { TiArrowMaximise, TiTicket } from 'react-icons/ti';
import { RiTicket2Line } from 'react-icons/ri';
import { BiArea, BiAtom, BiCustomize } from 'react-icons/bi';
import { MdPayment } from 'react-icons/md';

import { IoIosLogOut, IoIosSettings } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setToggleSidebar } from '../../actions/app';
import { logout } from '../../actions/auth';
import larsa from '../../assets/larsa.svg';
import { useSpring, animated } from 'react-spring';
import { BiMailSend, BiListUl, BiCast } from 'react-icons/bi';
import { FaCalendarCheck, FaCheckDouble, FaHammer } from 'react-icons/fa';

import {
  FiLayout,
  FiTag,
  FiGrid,
  FiUsers,
  FiLayers,
  FiFile,
  FiFileText,
  FiSquare,
  FiBook,
} from 'react-icons/fi';
import { flushAllCache, readLogo, readOptions } from '../../actions/options';
import eadLogo from '../../assets/ead-logo.svg';
import { readSchemas } from '../../actions/typing/schemas';
import { RiSettings2Line } from 'react-icons/ri';
import { AiFillReconciliation } from 'react-icons/ai';
import ServicePart from '../../newComponents/Sidebar/ServicePart';
import ACL from '../../acl/ACL';
import { readCategoriesMain } from '../../actions/tahkeem/categories';

const Sidebar = ({
  setToggleSidebar,
  logout,
  appReducer: { toggleSidebar, hideSidebar },
  schemasReducer: { schemas },
  optionsReducer: { logo, readable },
  tCategoriesReducer,
  readOptions,
  readLogo,
  readSchemas,
  flushAllCache,
  readCategoriesMain,
}) => {
  const { t } = useTranslation();
  const [imagePrev, setImagePrev] = useState('');

  const [state, setState] = useState([]);
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  useEffect(() => {
    if (!readable) {
      readOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readable]);

  useEffect(() => {
    if (
      window.location.hostname.includes('sakr') ||
      window.location.hostname.includes('localhost')
    ) {
      readCategoriesMain();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logo !== 'logo' && imagePrev === '') {
      readLogo(logo)
        .then((res) => {
          if (res.status === 200) {
            setImagePrev(res.data.file.path);
          } else {
            setImagePrev(larsa);
          }
        })
        .catch((err) => setImagePrev(larsa));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo, imagePrev]);

  const fadeClick = useSpring({ opacity: hideSidebar ? 0 : 1 });

  return (
    <>
      <animated.aside
        className={`main-menu ${
          window.location.hostname.includes('lecocq')
            ? 'bg-gray-p border-r border-gray-500'
            : 'bg-blue-lar-light'
        }  pl-8 h-full  fixed bottom-0 z-50 flex flex-col justify-start items-start border-r-gray-400 w-64`}
        style={(fadeClick, { padding: '30px 0' })}
      >
        <animated.div
          className={`justify-center items-center w-48  hidden md:flex border-b ${
            window.location.hostname.includes('lecocq')
              ? 'border-bg-gray-500'
              : 'border-blue-500'
          } `}
          style={(fade, { padding: '0 0 10px 0', height: 65 })}
        >
          <img
            className="h-full"
            src={
              window.location.hostname.includes('green') ||
              window.location.hostname.includes('ead')
                ? eadLogo
                : imagePrev
            }
            alt="larsa"
          />
        </animated.div>
        <div
          className="flex flex-col w-full overflow-y-auto"
          style={{ height: '90vh' }}
        >
          {
            // !window.location.hostname.includes('fablab') &&
            <ServicePart
              serviceName="types"
              serviceTitle="Types"
              service={true}
              allowedRoles={
                window.location.hostname.includes('badr')
                  ? ['super']
                  : ['super', 'administrator']
              }
              setState={setState}
              state={state}
              mainIcon={<BiAtom size={30} />}
              items={[
                {
                  url: '/schemas',
                  name: 'Schemas',
                  icon: <BiCustomize className="mx-2" size={20} />,
                },
                {
                  url: '/custom-fields',
                  name: 'Custom Fields',
                  icon: <BiArea className="mx-2" size={20} />,
                },
              ]}
            />
          }
          {!window.location.hostname.includes('fablab') &&
            !window.location.hostname.includes('lecocq') && (
              <ServicePart
                serviceName="types"
                relativeName="services"
                serviceTitle="Services"
                service={true}
                allowedRoles={[
                  'super',
                  'administrator',
                  'editor',
                  'author',
                  'reviewer',
                  'awardsAdmin',
                  'adminAssistant',
                ]}
                setState={setState}
                state={state}
                mainIcon={<FiSquare size={30} />}
                items={[
                  ...schemas.map((schema) => {
                    return {
                      url: `/services/${schema.name}`,
                      name: schema.title,
                      allowedRoles:
                        schema.name === 'school_education_awards' ||
                        schema.name ===
                          'applied_research_global_award_of_gifted_education'
                          ? [
                              'super',
                              'administrator',
                              'awardsAdmin',
                              'adminAssistant',
                            ]
                          : ['super', 'administrator', 'adminAssistant'],
                    };
                  }),
                ]}
                onClick={() => {
                  state.includes('services')
                    ? setState(state.filter((s) => s !== 'services'))
                    : setState([...state, 'services']);
                  readSchemas();
                }}
              />
            )}
          {window.location.hostname.includes('fablab') && (
            <ServicePart
              serviceName="types"
              relativeName="services"
              serviceTitle="Schemas"
              service={true}
              allowedRoles={[
                'super',
                'administrator',
                'editor',
                'author',
                'reviewer',
                'awardsAdmin',
                'adminAssistant',
              ]}
              setState={setState}
              state={state}
              mainIcon={<FiSquare size={30} />}
              items={[
                ...schemas.map((schema) => {
                  return {
                    url: `/services/${schema.name}`,
                    name: schema.title,
                    allowedRoles: ['super', 'administrator'],
                  };
                }),
              ]}
              onClick={() => {
                state.includes('services')
                  ? setState(state.filter((s) => s !== 'services'))
                  : setState([...state, 'services']);
                readSchemas();
              }}
            />
          )}
          {window.location.hostname.includes('lecocq') && (
            <ServicePart
              serviceName="types"
              relativeName="services"
              serviceTitle="Services"
              service={true}
              allowedRoles={[
                'super',
                'administrator',
                'editor',
                'author',
                'reviewer',
              ]}
              setState={setState}
              state={state}
              mainIcon={<FiSquare size={30} />}
              items={[
                {
                  url: `/services/covers`,
                  name: 'Hero Banner',
                },
                {
                  url: `/services/pages`,
                  name: 'Independent Page',
                },
                {
                  url: `/services/whatwedos`,
                  name: 'Ethos page',
                },
                {
                  url: `/services/whatwedos_timelines`,
                  name: 'Our story timeline',
                },

                {
                  url: `/services/regulatoryfinances`,
                  name: 'Regulatory Finance page',
                },
                {
                  url: `/services/team_members`,
                  name: 'Team Members',
                },

                {
                  url: `/services/posts`,
                  name: 'Posts',
                },
                {
                  url: `/services/filterYears`,
                  name: 'Year filter posts',
                },
                {
                  url: `/services/newletters_subscribers`,
                  name: 'Content subscribers',
                },
                {
                  url: `/services/contactmsgs`,
                  name: 'Contact us submissions',
                },
                {
                  url: `/services/careers`,
                  name: 'Careers',
                },

                {
                  url: `/services/jobapplicants`,
                  name: 'Job submissions',
                },
                {
                  url: `/services/alternative_links`,
                  name: 'Alternative Link',
                },
              ]}
              onClick={() => {
                state.includes('services')
                  ? setState(state.filter((s) => s !== 'services'))
                  : setState([...state, 'services']);
                readSchemas();
              }}
            />
          )}
          <ServicePart
            serviceName="payment"
            serviceTitle="Payment"
            service={true}
            allowedRoles={['super', 'administrator', 'editor', 'SmartDubai']}
            setState={setState}
            state={state}
            mainIcon={<MdPayment size={30} />}
            items={[
              {
                url: '/payment/options',
                name: 'Payment Options',
                icon: <IoIosSettings className="mx-2" size={20} />,
                allowedRoles: ['super', 'administrator', 'editor'],
              },

              {
                url: '/payment/reconciliation',
                name: 'Reconciliation',
                icon: <AiFillReconciliation className="mx-2" size={20} />,
                allowedRoles: ['SmartDubai'],
              },
            ]}
          />
          <ServicePart
            serviceName="el"
            serviceTitle="E Learning"
            service={true}
            allowedRoles={[
              'super',
              'administrator',
              'editor',
              'adminAssistant',
            ]}
            setState={setState}
            state={state}
            mainIcon={<FiLayout size={30} />}
            items={[
              {
                url: '/courses',
                name: t('Sidebar.Course'),
                icon: <RiBook2Line className="mx-2" size={20} />,
              },
              {
                url: '/path',
                name: t('Sidebar.Path'),
                icon: <FiLayers className="mx-2" size={20} />,
              },
              {
                url: '/eLearning/categories',
                name: window.location.hostname.includes('green')
                  ? 'TOPICS'
                  : t('Sidebar.Categories'),
                icon: <FiGrid className="mx-2" size={20} />,
              },
              {
                url: '/eLearning/tags',
                name: t('Sidebar.Tags'),
                icon: <FiTag className="mx-2" size={20} />,
              },
            ]}
          />
          <ServicePart
            serviceName="blog"
            serviceTitle={t('CMS')}
            service={true}
            allowedRoles={['super', 'administrator', 'editor']}
            setState={setState}
            state={state}
            mainIcon={<FiLayout size={30} />}
            items={[
              {
                url: '/pages',
                name: t('Pages'),
                icon: <FiFile className="mx-2" size={20} />,
              },
              {
                url: '/posts',
                name: t('Posts'),
                icon: <FiFileText className="mx-2" size={20} />,
              },
              {
                url: '/menus',
                name: t('Menus'),
                icon: <RiMenuAddLine className="mx-2" size={20} />,
              },
              {
                url: '/blog/categories',
                name: t('Sidebar.Categories'),
                icon: <FiGrid className="mx-2" size={20} />,
              },
              {
                url: '/blog/tags',
                name: t('Sidebar.Tags'),
                icon: <FiTag className="mx-2" size={20} />,
              },
            ]}
          />
          <ServicePart
            serviceName="tahkeem"
            relativeName="awards"
            serviceTitle={t(`Sidebar.Awards`)}
            service={true}
            allowedRoles={['super', 'administrator']}
            setState={setState}
            state={state}
            mainIcon={<RiAwardLine size={30} />}
            onClick={() => {
              state.includes('awards')
                ? setState(state.filter((s) => s !== 'awards'))
                : setState([...state, 'awards']);
            }}
            items={[
              {
                url: '/tahkeem/awards',
                name: t(`Sidebar.Categories`),
                icon: <FiFileText className="mx-2" size={20} />,
                allowedRoles: ['super', 'administrator'],
              },
            ]}
          />
          {tCategoriesReducer.categoriesMain.map((category) => (
            <ServicePart
              serviceName="tahkeem"
              relativeName={category._id}
              serviceTitle={category.name}
              service={true}
              allowedRoles={
                category._id === '6040b609940cf9805ab23084'
                  ? ['super', 'administrator']
                  : ['super', 'administrator', 'judge']
              }
              setState={setState}
              state={state}
              mainIcon={<RiAwardLine size={30} />}
              onClick={() => {
                state.includes(category._id)
                  ? setState(state.filter((s) => s !== category._id))
                  : setState([...state, category._id]);
              }}
              items={[
                // {
                //   url: '/tahkeem/forms',
                //   name: t(`Sidebar.Forms`),
                //   icon: <RiMenuAddLine className="mx-2" size={20} />,
                //   allowedRoles: ['super', 'administrator'],
                // },
                // {
                //   url: '/tahkeem/judgments',
                //   name: t(`Sidebar.Judgment`),
                //   icon: <FaHammer className="mx-2" size={20} />,
                //   allowedRoles: ['super', 'administrator'],
                // },
                // {
                //   url: '/tahkeem/assessments',
                //   name: t(`Sidebar.Assessment`),
                //   icon: <FaCheckDouble className="mx-2" size={20} />,
                //   allowedRoles: ['super', 'administrator'],
                // },
                // {
                //   url: '/tahkeem/scenarios',
                //   name: t(`Sidebar.Scenarios`),
                //   icon: <FiBook className="mx-2" size={20} />,
                //   allowedRoles: ['super', 'administrator'],
                // },
                {
                  url: `/tahkeem/${category._id}/categories`,
                  name: t(`Sidebar.Categories`),
                  icon: <FiFileText className="mx-2" size={20} />,
                  allowedRoles: ['super', 'administrator'],
                },
                {
                  url: `/tahkeem/${category._id}/cycles`,
                  name: t(`Sidebar.Cycles`),
                  icon: <FaCalendarCheck className="mx-2" size={20} />,
                  allowedRoles: ['super', 'administrator'],
                },
                {
                  url: `/tahkeem/${category._id}/seasons`,
                  name: t(`Sidebar.Seasons`),
                  icon: <FiGrid className="mx-2" size={20} />,
                  allowedRoles: ['super', 'administrator'],
                },
                {
                  url: `/tahkeem/${category._id}/applications`,
                  name: t(`Sidebar.Applications`),
                  icon: <RiSendPlane2Fill className="mx-2" size={20} />,
                  allowedRoles: ['super', 'administrator', 'judge'],
                },
              ]}
            />
          ))}
          {/* <ServicePart
            serviceName="tahkeem"
            serviceTitle={t(`Sidebar.Tahkeem`)}
            service={true}
            allowedRoles={['super', 'administrator', 'editor', 'judge']}
            setState={setState}
            state={state}
            mainIcon={<RiAwardLine size={30} />}
            items={[
              {
                url: '/tahkeem/applications',
                name: t(`Sidebar.Applications`),
                icon: <RiSendPlane2Fill className="mx-2" size={20} />,
              },

              // {
              //   url: '/tahkeem/awards',
              //   name: t(`Sidebar.Awards`),
              //   icon: <FiGrid className="mx-2" size={20} />,
              // },
            ]}
          /> */}
          <ServicePart
            serviceName="newsletter"
            serviceTitle="Newsletter"
            service={true}
            allowedRoles={['administrator', 'super']}
            setState={setState}
            state={state}
            mainIcon={<BiMailSend size={30} />}
            items={[
              {
                url: '/newsletter/lists',
                name: 'Lists',
                icon: <BiListUl className="mx-2" size={20} />,
              },
              {
                url: '/newsletter/campaigns',
                name: 'Campaigns',
                icon: <BiCast className="mx-2" size={20} />,
              },
              {
                url: '/newsletter/options',
                name: 'Options',
                icon: <RiSettings2Line className="mx-2" size={20} />,
              },
            ]}
          />
          {/* <ServicePart
            serviceName="survey"
            serviceTitle="Surveys"
            service={true}
            allowedRoles={['administrator', 'super']}
            setState={setState}
            state={state}
            mainIcon={<RiSurveyFill size={30} />}
            items={[
              {
                url: '/surveys/',
                name: 'Surveys',
                icon: <RiSurveyLine className="mx-2" size={20} />,
              },
            ]}
          /> */}
          {!window.location.hostname.includes('fablab') &&
            !window.location.hostname.includes('localhost') &&
            !window.location.hostname.includes('wgc') && (
              <ServicePart
                serviceName="ticketing"
                serviceTitle={'Ticketing'}
                service={true}
                allowedRoles={['super', 'administrator']}
                setState={setState}
                state={state}
                mainIcon={<TiTicket size={30} />}
                items={[
                  {
                    url: '/ticket-types',
                    name: 'Ticket Types',
                    icon: <RiTicket2Line className="mx-2" size={20} />,
                  },
                  {
                    url: '/ticket/categories',
                    name: window.location.hostname.includes('green')
                      ? 'TOPICS'
                      : t('Sidebar.Categories'),
                    icon: <FiGrid className="mx-2" size={20} />,
                  },
                  {
                    url: '/ticket/tags',
                    name: t('Sidebar.Tags'),
                    icon: <FiTag className="mx-2" size={20} />,
                  },
                ]}
              />
            )}
          {window.location.hostname.includes('fablab') && (
            <ServicePart
              serviceName="ticketing"
              serviceTitle={'Services'}
              service={true}
              allowedRoles={['super', 'administrator']}
              setState={setState}
              state={state}
              mainIcon={<TiTicket size={30} />}
              items={[
                {
                  url: '/ticket-types',
                  name: 'Workshop',
                  icon: <RiTicket2Line className="mx-2" size={20} />,
                },
                {
                  url: '/services-types',
                  name: 'Fablab Services',
                  icon: <RiTicket2Line className="mx-2" size={20} />,
                },
                {
                  url: '/ticket/categories',
                  name: window.location.hostname.includes('green')
                    ? 'TOPICS'
                    : t('Sidebar.Categories'),
                  icon: <FiGrid className="mx-2" size={20} />,
                },
                {
                  url: '/ticket/tags',
                  name: t('Sidebar.Tags'),
                  icon: <FiTag className="mx-2" size={20} />,
                },
              ]}
            />
          )}

          {(window.location.hostname.includes('wgc') ||
            window.location.hostname.includes('localhost')) && (
            <ServicePart
              serviceName="ticketing"
              serviceTitle={'Ticketing and Conferences'}
              service={true}
              allowedRoles={['super', 'administrator', 'ticketingModerator']}
              setState={setState}
              state={state}
              mainIcon={<TiTicket size={30} />}
              items={
                window.location.hostname.includes('localhost')
                  ? [
                      {
                        url: '/tickets',
                        name: 'tickets',
                        icon: <RiTicket2Line className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket/categories',
                        name: window.location.hostname.includes('green')
                          ? 'TOPICS'
                          : t('Sidebar.Categories'),
                        icon: <FiGrid className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket/tags',
                        name: t('Sidebar.Tags'),
                        icon: <FiTag className="mx-2" size={20} />,
                      },
                    ]
                  : [
                      {
                        url: '/ticket-con-workshop',
                        name: 'Conferences Workshop',
                        icon: <RiTicket2Line className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket-conferences',
                        name: 'Conferences',
                        icon: <RiTicket2Line className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket-el-workshop',
                        name: 'El Workshop',
                        icon: <RiTicket2Line className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket/categories',
                        name: window.location.hostname.includes('green')
                          ? 'TOPICS'
                          : t('Sidebar.Categories'),
                        icon: <FiGrid className="mx-2" size={20} />,
                      },
                      {
                        url: '/ticket/tags',
                        name: t('Sidebar.Tags'),
                        icon: <FiTag className="mx-2" size={20} />,
                      },
                    ]
              }
            />
          )}
          <ServicePart
            serviceName="media"
            serviceTitle={t('Sidebar.Media')}
            service={false}
            url="/media"
            allowedRoles={['super', 'administrator', 'editor', 'reviewer']}
            setState={setState}
            state={state}
            mainIcon={<BsImages size={30} />}
          />
          {window.location.hostname.includes('abu-dhabi-clean-app') ||
            (window.location.hostname.includes('localhost') && (
              <ServicePart
                serviceName="users"
                serviceTitle={'Users Campaign'}
                service={false}
                url="/users-dhabi"
                allowedRoles={['super', 'administrator']}
                setState={setState}
                state={state}
                mainIcon={<FiUsers size={30} />}
              />
            ))}
          {/* )}{' '} */}
          <ServicePart
            serviceName="users"
            serviceTitle={t('Sidebar.Users')}
            service={false}
            url="/users"
            allowedRoles={['super', 'administrator']}
            setState={setState}
            state={state}
            mainIcon={<FiUsers size={30} />}
          />
          {!window.location.hostname.includes('lecocq') && (
            <ServicePart
              serviceName="roles"
              serviceTitle={t('Sidebar.Roles')}
              service={false}
              url="/roles"
              allowedRoles={['super', 'administrator']}
              setState={setState}
              state={state}
              mainIcon={<RiPencilRuler2Line size={30} />}
            />
          )}
          {!window.location.hostname.includes('lecocq') &&
            !window.location.hostname.includes('wgc') &&
            !window.location.hostname.includes('fablab') && (
              <ServicePart
                serviceName="capabilities"
                serviceTitle={t('Sidebar.Capabilities')}
                service={false}
                url="/capabilities"
                allowedRoles={['super', 'administrator']}
                setState={setState}
                state={state}
                mainIcon={<TiArrowMaximise size={30} />}
              />
            )}
          <ServicePart
            serviceName="login"
            serviceTitle={t('Sidebar.Logout')}
            service={false}
            url="/login"
            allowedRoles={[
              'super',
              'administrator',
              'editor',
              'judge',
              'author',
              'awardsAdmin',
              'adminAssistant',
              'ticketingModerator',
            ]}
            setState={setState}
            state={state}
            mainIcon={<IoIosLogOut size={30} />}
            onClick={() => {
              logout();
            }}
          />
        </div>
      </animated.aside>
    </>
  );
};

const mapStateToProps = (state) => ({
  appReducer: state.appReducer,
  authReducer: state.authReducer,
  optionsReducer: state.optionsReducer,
  schemasReducer: state.schemasReducer,
  tCategoriesReducer: state.tCategoriesReducer,
});

export default connect(mapStateToProps, {
  setToggleSidebar,
  logout,
  readLogo,
  readSchemas,
  readOptions,
  readCategoriesMain,
  flushAllCache,
})(Sidebar);
