import {
  NEWSLETTER_READ_CAMPAIGNS,
  NEWSLETTER_START_CAMPAIGNS_RELOAD,
  NEWSLETTER_FINISHED_CAMPAIGNS_RELOAD,
  NEWSLETTER_CLEAR_CAMPAIGN,
  NEWSLETTER_CREATE_CAMPAIGN,
  NEWSLETTER_UPDATE_CAMPAIGN,
  NEWSLETTER_DELETE_CAMPAIGN,
} from '../../actions/types';

const initialState = {
  campaigns: [],
  campaign: {},
  error: {},
  loading: false,
  readable: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NEWSLETTER_READ_CAMPAIGNS:
      return { ...state, campaigns: payload.campaigns, readable: true };
    case NEWSLETTER_START_CAMPAIGNS_RELOAD:
      return { ...state, loading: true };
    case NEWSLETTER_FINISHED_CAMPAIGNS_RELOAD:
      return { ...state, loading: false };
    case NEWSLETTER_CREATE_CAMPAIGN:
      return {
        ...state,
        // campaigns: [...state.campaigns, payload]
        readable: false,
      };
    case NEWSLETTER_UPDATE_CAMPAIGN:
      return {
        ...state,
        // campaigns: [...state.campaigns, payload]
        readable: false,
      };
    case NEWSLETTER_DELETE_CAMPAIGN:
      return {
        ...state,
        campaigns: [
          ...state.campaigns.filter(
            (campaign) => campaign.id !== payload.campaign.id
          ),
        ],
      };
    case NEWSLETTER_CLEAR_CAMPAIGN:
      return { ...state, campaign: {} };
    default:
      return state;
  }
};
