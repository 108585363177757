import {
  READ_SERVICES,
  READ_ONE_SERVICE,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  CLEAR_SERVICE,
  START_SERVICES_RELOAD,
  FINISHED_SERVICES_RELOAD,
} from '../../actions/types';

const initialState = {
  services: [],
  service: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SERVICES:
      return {
        ...state,
        services: [...payload.contents],
        readable: true,
      };
    case READ_ONE_SERVICE:
      return {
        ...state,
        service: { ...payload.content },
      };
    case CREATE_SERVICE:
      return {
        ...state,
        services: [payload, ...state.services],
      };
    case UPDATE_SERVICE:
      return {
        ...state,
        services: [
          ...state.services.map((service) =>
            service.id === payload.content.id ? payload.content : service
          ),
        ],
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: [
          ...state.services.filter(
            (service) => service.id !== payload.service.id
          ),
        ],
      };
    case CLEAR_SERVICE:
      return {
        ...state,
        service: {},
      };

    case START_SERVICES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SERVICES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
