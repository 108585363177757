export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_CYCLE_RELOAD = 'START_CYCLE_RELOAD';
export const FINISHED_CYCLE_RELOAD = 'FINISHED_CYCLE_RELOAD';

export const READ_CYCLES = 'READ_CYCLES';
export const READ_SINGLE_CYCLE = 'READ_SINGLE_CYCLE';
export const CREATE_CYCLE = 'CREATE_CYCLE';
export const UPDATE_CYCLE = 'UPDATE_CYCLE';
export const DELETE_CYCLE = 'DELETE_CYCLE';
export const CLEAR_CYCLE = 'CLEAR_CYCLE';
export const CYCLES_ERROR = 'CYCLES_ERROR';
