import { getHeaders } from '../../helpers/getHeaders';
import { getServiceUrl } from '../../helpers/getServiceUrl';
import {
  READ_T_CATEGORIES,
  READ_SINGLE_T_CATEGORY,
  CREATE_T_CATEGORY,
  READ_T_CATEGORIES_MAIN,
  UPDATE_T_CATEGORY,
  DELETE_T_CATEGORY,
  CLEAR_T_CATEGORY,
  T_CATEGORIES_ERROR,
  SERVICE_NAME,
  START_T_CATEGORY_RELOAD,
  FINISHED_T_CATEGORY_RELOAD,
  READ_T_CATEGORIES_TREE,
} from '../../types/tahkeem/categories';
import { readItemsAsync } from '../equCurd/readItems';
import { readOneItemAsync } from '../equCurd/readOneItem';
import { createItemAsync } from '../equCurd/createItem';
import { updateItemAsync } from '../equCurd/updateItem';
import { deleteItemAsync } from '../equCurd/deleteItem';

export const readCategories = (query, language) =>
  readItemsAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories/all',
      query,
    }),
    startReload,
    finishedReload,
    successType: READ_T_CATEGORIES,
    errorType: T_CATEGORIES_ERROR,

    headers: getHeaders(language),
  });

export const readCategoriesMain = (query, language) =>
  readItemsAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories/tree',
      query,
    }),
    startReload,
    finishedReload,
    successType: READ_T_CATEGORIES_MAIN,
    errorType: T_CATEGORIES_ERROR,

    headers: getHeaders(language),
  });

export const readCategoriesTree = (query, language) =>
  readItemsAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories/tree',
      query,
    }),
    startReload,
    finishedReload,
    successType: READ_T_CATEGORIES_TREE,
    errorType: T_CATEGORIES_ERROR,

    headers: getHeaders(language),
  });

export const readSingleCategory = (id, language) =>
  readOneItemAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories',
    }),
    startReload,
    finishedReload,
    successType: READ_SINGLE_T_CATEGORY,
    errorType: T_CATEGORIES_ERROR,
    id,
    headers: getHeaders(language),
  });

export const createCategory = (formData, language) =>
  createItemAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories',
    }),
    startReload,
    finishedReload,
    successType: CREATE_T_CATEGORY,
    errorType: T_CATEGORIES_ERROR,
    formData,
    title: 'Categories',
    headers: getHeaders(language),
  });

export const updateCategory = (formData, language) =>
  updateItemAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories',
    }),
    startReload,
    finishedReload,
    successType: UPDATE_T_CATEGORY,
    errorType: T_CATEGORIES_ERROR,
    formData,
    id: formData._id,
    title: 'Categories',
    headers: getHeaders(language),
  });

export const deleteCategory = (id, language) =>
  deleteItemAsync({
    url: getServiceUrl({
      serviceName: SERVICE_NAME,
      servicePath: 'tahkeem',
      path: 'categories',
    }),
    startReload,
    finishedReload,
    successType: DELETE_T_CATEGORY,
    errorType: T_CATEGORIES_ERROR,
    id: id,
    title: 'Categories',
    headers: getHeaders(language),
  });

export const startReload = () => (dispatch) => {
  dispatch({ type: START_T_CATEGORY_RELOAD });
};

export const finishedReload = () => (dispatch) => {
  dispatch({ type: FINISHED_T_CATEGORY_RELOAD });
};

export const clearCategory = () => (dispatch) => {
  dispatch({ type: CLEAR_T_CATEGORY });
};
