export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_APPLICATION_RELOAD = 'START_APPLICATION_RELOAD';
export const FINISHED_APPLICATION_RELOAD = 'FINISHED_APPLICATION_RELOAD';

export const READ_APPLICATIONS = 'READ_APPLICATIONS';
export const READ_SINGLE_APPLICATION = 'READ_SINGLE_APPLICATION';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const RESULT_APPLICATION = 'RESULT_APPLICATION';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const APPLICATIONS_ERROR = 'APPLICATIONS_ERROR';
export const DOWNLOAD_APPLICATIONS = 'DOWNLOAD_APPLICATIONS';
