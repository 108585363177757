export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_SCENARIO_RELOAD = 'START_SCENARIO_RELOAD';
export const FINISHED_SCENARIO_RELOAD = 'FINISHED_SCENARIO_RELOAD';

export const READ_SCENARIOS = 'READ_SCENARIOS';
export const READ_SINGLE_SCENARIO = 'READ_SINGLE_SCENARIO';
export const CREATE_SCENARIO = 'CREATE_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const CLEAR_SCENARIO = 'CLEAR_SCENARIO';
export const SCENARIOS_ERROR = 'SCENARIOS_ERROR';
