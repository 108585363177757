import {
  TOGGLE_SIDEBAR,
  SET_EDIT_MODE,
  SHOW_COVER_MODE,
  HIDE_COVER_MODE,
  UNSET_EDIT_MODE,
  SET_PROGRESS,
  UNSET_PROGRESS,
  SET_ACTIVE_LECTURE,
  SET_IS_MODEL,
  HIDE_SIDEBAR,
} from './types';

export const setToggleSidebar = () => (dispatch) => {
  dispatch({ type: TOGGLE_SIDEBAR });
};

export const setHideSidebar = () => (dispatch) => {
  dispatch({ type: HIDE_SIDEBAR });
};

export const setEditMode = () => (dispatch) => {
  dispatch({ type: SET_EDIT_MODE });
};

export const unSetEditMode = () => (dispatch) => {
  dispatch({ type: UNSET_EDIT_MODE });
};

export const showCoverMode = () => (dispatch) => {
  dispatch({ type: SHOW_COVER_MODE });
};
export const hideCoverMode = () => (dispatch) => {
  dispatch({ type: HIDE_COVER_MODE });
};
export const setProgress = (data) => (dispatch) => {
  dispatch({
    type: SET_PROGRESS,
    payload: data,
  });
};
export const setIsModel = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_MODEL,
    payload: data,
  });
};
export const unSetProgress = () => (dispatch) => {
  setTimeout(() => dispatch({ type: UNSET_PROGRESS }), 1000);
};

export const setActiveLecture = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LECTURE,
    payload: data,
  });
};

// onCleraProgress: setTimeout(() =>
// dispatch(
//   {
//     type: UNSET_PROGRESS,
//     payload: percentCompleted
//   },
//   10000
// )
// )
