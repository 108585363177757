export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_T_CATEGORY_RELOAD = 'START_T_CATEGORY_RELOAD';
export const FINISHED_T_CATEGORY_RELOAD = 'FINISHED_T_CATEGORY_RELOAD';

export const READ_T_CATEGORIES = 'READ_T_CATEGORIES';
export const READ_T_CATEGORIES_TREE = 'READ_T_CATEGORIES_TREE';
export const READ_T_CATEGORIES_MAIN = 'READ_T_CATEGORIES_MAIN';

export const READ_SINGLE_T_CATEGORY = 'READ_SINGLE_T_CATEGORY';
export const CREATE_T_CATEGORY = 'CREATE_T_CATEGORY';
export const UPDATE_T_CATEGORY = 'UPDATE_T_CATEGORY';
export const DELETE_T_CATEGORY = 'DELETE_T_CATEGORY';
export const CLEAR_T_CATEGORY = 'CLEAR_T_CATEGORY';
export const T_CATEGORIES_ERROR = 'T_CATEGORIES_ERROR';
