import {
  READ_OPTIONS,
  UPDATE_OPTIONS,
  CREATE_LOGO,
  READ_LOGO,
  CREATE_PREVIEW_CERT,
  MAIN_CERT,
  START_OPTIONS_RELOAD,
  FINISHED_OPTIONS_RELOAD,
} from '../actions/types';

const initialState = {
  options: [],
  general: [],
  login: [],
  email: [],
  certificat: [],
  ticketCertificat: [],
  ticketCertificatMain: 0,
  option: {},
  logo: 'logo',
  error: {},
  loading: false,
  readable: false,
};

const generalOptions = [
  'DEFAULT_ROLE',
  'BUSINESS_NAME',
  'BUSINESS_LOGO',
  'APP_URI',
  'FRONTEND_URL',
  'UPLOADS_PUBLIC_URL',
  'CLIENT_ABS_PATH',
  'ENABLE_REGISTRATION',
  'PREFERRED_IMG_TYPES',
  'PREFERRED_IMG_SIZES',
];
const logoOptions = ['BUSINESS_LOGO'];

const loginOptions = [
  'ENABLE_FACEBOOK_LOGIN',
  'FACEBOOK_PROVIDER_CLIENT_ID',
  'FACEBOOK_PROVIDER_CLIENT_SECRET',
  'ENABLE_GOOGLE_LOGIN',
  'GOOGLE_PROVIDER_CLIENT_ID',
  'GOOGLE_PROVIDER_CLIENT_SECRET',
];
const emailOptions = [
  'SMTP_HOST',
  'SMTP_FROM_NAME',
  'SMTP_FROM_EMAIL',
  'SMTP_REPLYTO_EMAIL',
  'SMTP_AUTH_USERNAME',
  'SMTP_AUTH_PASSWORD',
  'SMTP_PORT',
  'SMTP_SECURE',
  'CAPTCHA_SITE_KEY',
  'CAPTCHA_SECRET_KEY',
  'CONTACT_TO',
];

const certificateOptions = ['CERTIFICATE'];

const ticketCertificateOptions = ['TICKET_CERTIFICATES'];

export default function op(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_OPTIONS:
      let generalData = [
        ...generalOptions.map((generalOption) => {
          return (
            payload.options.find((option) => option.name === generalOption) ??
            {}
          );
        }),
      ];
      let logoData = logoOptions.map((logoOption) => {
        return payload.options.find((option) => option.name === logoOption);
      });
      let loginData = loginOptions.map((loginOption) => {
        return payload.options.find((option) => option.name === loginOption);
      });
      let emailData = emailOptions.map((emailOption) => {
        return payload.options.find((option) => option.name === emailOption);
      });
      let certificateData = certificateOptions.map((certificateOption) => {
        return payload.options.find(
          (option) => option.name === certificateOption
        );
      });

      let ticketCertificateData = ticketCertificateOptions.map(
        (ticketCertificateOption) => {
          return payload.options.find(
            (option) => option.name === ticketCertificateOption
          );
        }
      );

      return {
        ...state,
        general: [...generalData],
        login: [...loginData],
        email: [...emailData],
        certificat: [...certificateData],
        ticketCertificat: [...ticketCertificateData],
        logo: logoData[0].value,
        options: [...payload.options],
        readable: true,
      };

    case UPDATE_OPTIONS:
      return {
        ...state,
        // category: payload.category,
        // categories: [
        //   payload.category,
        //   ...state.categories.filter(
        //     category => category.id !== payload.category.id
        //   )
        // ]
      };
    case READ_LOGO:
      return {
        ...state,
        readable: true,
      };

    case MAIN_CERT:
      return {
        ...state,
        ticketCertificatMain: payload,
      };

    case CREATE_LOGO:
      return {
        ...state,
        logo: payload.id,
      };

    case CREATE_PREVIEW_CERT:
      return {
        ...state,
        preview: payload.id,
      };
    case START_OPTIONS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_OPTIONS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
