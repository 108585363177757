import {
  READ_CYCLES,
  READ_SINGLE_CYCLE,
  CREATE_CYCLE,
  UPDATE_CYCLE,
  DELETE_CYCLE,
  CLEAR_CYCLE,
  // CYCLES_ERROR,
  START_CYCLE_RELOAD,
  FINISHED_CYCLE_RELOAD,
} from '../../types/tahkeem/cycles';

const initialState = {
  cycles: [],
  cycle: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_CYCLES:
      return {
        ...state,
        cycles: [...payload.cycles],
        readable: true,
      };
    case READ_SINGLE_CYCLE:
      return {
        ...state,
        cycle: { ...payload.cycle },
      };
    case CREATE_CYCLE:
      return {
        ...state,
        cycles: [payload, ...state.cycles],
      };
    case UPDATE_CYCLE:
      return {
        ...state,
        cycles: [
          ...state.cycles.map((cycle) =>
            cycle._id === payload.cycle._id ? payload.cycle : cycle
          ),
        ],
      };
    case DELETE_CYCLE:
      return {
        ...state,
        cycles: [
          ...state.cycles.filter((cycle) => cycle._id !== payload.cycle._id),
        ],
      };
    case CLEAR_CYCLE:
      return {
        ...state,
        cycle: {},
      };

    case START_CYCLE_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_CYCLE_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
