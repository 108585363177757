import {
  READ_MENUS,
  READ_ONE_MENU,
  CREATE_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  CLEAR_MENU,
  START_MENUS_RELOAD,
  FINISHED_MENUS_RELOAD,
} from '../../actions/types';

const initialState = {
  menus: [],
  menu: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_MENUS:
      return {
        ...state,
        menus: [...payload.menus],
        readable: true,
      };
    case READ_ONE_MENU:
      return {
        ...state,
        menu: { ...payload.menu },
      };
    case CREATE_MENU:
      return {
        ...state,
        menus: [payload, ...state.menus],
      };
    case UPDATE_MENU:
      return {
        ...state,
        menus: [
          ...state.menus.map((menu) =>
            menu.id === payload.menu.id ? payload.menu : menu
          ),
        ],
      };
    case DELETE_MENU:
      return {
        ...state,
        menus: [...state.menus.filter((menu) => menu.id !== payload.menu.id)],
      };
    case CLEAR_MENU:
      return {
        ...state,
        menu: {},
      };
    case START_MENUS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_MENUS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
