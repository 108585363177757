export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_SEASON_RELOAD = 'START_SEASON_RELOAD';
export const FINISHED_SEASON_RELOAD = 'FINISHED_SEASON_RELOAD';

export const READ_SEASONS = 'READ_SEASONS';
export const READ_SINGLE_SEASON = 'READ_SINGLE_SEASON';
export const CREATE_SEASON = 'CREATE_SEASON';
export const UPDATE_SEASON = 'UPDATE_SEASON';
export const DELETE_SEASON = 'DELETE_SEASON';
export const CLEAR_SEASON = 'CLEAR_SEASON';
export const SEASONS_ERROR = 'SEASONS_ERROR';
