import {
  READ_PAYMENT_OPTIONS,
  UPDATE_PAYMENT_OPTIONS,
  PAYMENT_ERROR,
  START_PAYMENT_RELOAD,
  FINISHED_PAYMENT_RELOAD,
} from '../../actions/types';

const initialState = {
  options: [],
  option: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_PAYMENT_OPTIONS:
      return {
        ...state,
        options: [...payload.options],
        readable: true,
      };
    case UPDATE_PAYMENT_OPTIONS:
      return {
        ...state,
        options: [...payload.options],
        readableOne: true,
      };

    case PAYMENT_ERROR:
      return {
        ...state,
        options: [],
        option: {},
        error: {},
        loading: false,
        readable: false,
      };

    case START_PAYMENT_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_PAYMENT_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
