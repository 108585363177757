export const hasSubscription = (serviceName) => {
  return (
    (typeof process.env.REACT_APP_CLIENT_ID !== 'undefined' &&
      process.env.REACT_APP_SERVICES.includes(serviceName)) ||
    (localStorage.getItem('REACT_APP_CLIENT_ID') !== null &&
      JSON.parse(localStorage.getItem('REACT_APP_SERVICES')).includes(
        serviceName
      ))
  );
};
