import {
  READ_JUDGMENTS,
  READ_JUDGMENTS_TREE,
  READ_SINGLE_JUDGMENT,
  CREATE_JUDGMENT,
  UPDATE_JUDGMENT,
  DELETE_JUDGMENT,
  CLEAR_JUDGMENT,
  // JUDGMENTS_ERROR,
  START_JUDGMENT_RELOAD,
  FINISHED_JUDGMENT_RELOAD,
} from '../../types/tahkeem/judgments';
const initialState = {
  judgments: [],
  judgmentsTree: [],
  judgment: {},
  error: {},
  loading: false,
  readable: false,
};

const addData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [{ ...payload, children: [] }, ...d.children],
        }
      : { ...d, children: addData(d.children, payload) }
  );
  return data;
};

const deleteData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [...d.children.filter((f) => f._id !== payload._id)],
        }
      : { ...d, children: deleteData(d.children, payload) }
  );
  return data;
};

const updateData = (data, payload) => {
  data = data.map((d) =>
    payload.parent === d._id
      ? {
          ...d,
          children: [
            ...d.children.map((f) =>
              f._id === payload._id
                ? { ...payload, children: [...f.children] }
                : f
            ),
          ],
        }
      : { ...d, children: deleteData(d.children, payload) }
  );
  return data;
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_JUDGMENTS:
      return {
        ...state,
        judgments: [...payload.judgments],
        readable: true,
      };
    case READ_JUDGMENTS_TREE:
      return {
        ...state,
        judgmentsTree: [...payload.judgments],
        readable: true,
      };

    case READ_SINGLE_JUDGMENT:
      return {
        ...state,
        judgment: { ...payload.judgment },
      };
    case CREATE_JUDGMENT:
      return {
        ...state,
        judgments: [...state.judgments, payload.judgment],
        judgmentsTree:
          payload.judgment.parent === null
            ? [...state.judgmentsTree, { ...payload.judgment, children: [] }]
            : addData(state.judgmentsTree, payload.judgment),
      };
    case UPDATE_JUDGMENT:
      return {
        ...state,
        judgments: [
          ...state.judgments.map((judgment) =>
            judgment._id === payload.judgment._id ? payload.judgment : judgment
          ),
        ],
        judgmentsTree:
          payload.judgment.parent === null
            ? [
                ...state.judgmentsTree.map((judgment) =>
                  judgment._id === payload.judgment._id
                    ? { ...payload.judgment, children: [...judgment.children] }
                    : judgment
                ),
              ]
            : updateData(state.judgmentsTree, payload.judgment),
      };
    case DELETE_JUDGMENT:
      return {
        ...state,
        judgments: [
          ...state.judgments.filter(
            (judgment) => judgment._id !== payload.judgment._id
          ),
        ],

        judgmentsTree:
          payload.judgment.parent === null
            ? [
                ...state.judgmentsTree.filter(
                  (judgment) => judgment._id !== payload.judgment._id
                ),
              ]
            : deleteData(state.judgmentsTree, payload.judgment),
      };
    case CLEAR_JUDGMENT:
      return {
        ...state,
        judgment: {},
      };

    case START_JUDGMENT_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_JUDGMENT_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
