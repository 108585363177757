import {
  READ_COURSES,
  SEARCH_COURSES,
  READ_ONE_COURSE,
  CREATE_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  CLEAR_COURSE,
  SAVE_COURSE,
  SET_COURSES_CURRENT_PAGE,
  START_COURSES_RELOAD,
  FINISHED_COURSES_RELOAD,
} from '../../actions/types';

const initialState = {
  courses: [],
  coursesCurrentPage: 1,
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  course: {},
  error: {},
  loading: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_COURSES:
    case SEARCH_COURSES:
      return {
        ...state,
        courses: [...payload.courses.filter((course) => !course.trash)],
        pagination: {
          ...state.pagination,
          totalPages: payload.pagination.totalPages,
          perPage: payload.pagination.perPage,
          totalCount: payload.pagination.totalCount,
        },
      };
    case READ_ONE_COURSE:
    case SAVE_COURSE:
      return {
        ...state,
        course: { ...payload.course },
      };

    case CREATE_COURSE:
      return {
        ...state,
        course: { ...state.course, ...payload },
      };

    case UPDATE_COURSE:
      return {
        ...state,
        course: { ...state.course, ...payload },
      };
    case DELETE_COURSE:
      return {
        ...state,
        courses: [
          ...state.courses.filter((course) => course.id !== payload.course.id),
        ],
      };

    case CLEAR_COURSE:
      return {
        ...state,
        course: {},
      };

    case SET_COURSES_CURRENT_PAGE:
      return {
        ...state,
        coursesCurrentPage: payload,
      };
    case START_COURSES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_COURSES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
