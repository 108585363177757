import {
	LOGIN,
	REGISTER,
	USER_LOADED,
	FORGET_PASSWORD,
	LOGOUT,
	GET_SUBSCRIPTIONS,
	START_AUTH_RELOAD,
	FINISHED_AUTH_RELOAD,
} from '../actions/types';

const initialState = {
	token: null,
	isAuthenticated: false,
	user: {},
	error: {},
	loading: false,
	services: [],
	clientID: null,
	info: null,
};

const auth = function(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case REGISTER:
			return {
				...state,
			};
		case USER_LOADED:
			return {
				...state,
				token: localStorage.getItem('token'),
				isAuthenticated: true,
				user: payload,
			};
		case GET_SUBSCRIPTIONS:
			let host;

			if (window.location.hostname.includes('e-green')) {
				host = 'ead';
			} else if (window.location.hostname.includes('localhost')) {
				host = 'ead';
			} else if (window.location.hostname.includes('wgc')) {
				host = 'wgc';
			} else if (window.location.hostname.includes('fablab')) {
				host = 'fablab';
			} else if (window.location.hostname.includes('sakr')) {
				host = 'saker';
			} else if (window.location.hostname.includes('dev')) {
				host = 'Dev';
			} else if (window.location.hostname.includes('wasp')) {
				host = 'WASP';
			} else if (window.location.hostname.includes('v-immunity')) {
				host = 'Vimmunity';
			} else if (window.location.hostname.includes('abu-dhabi-clean-app')) {
				host = 'Abu Dhabi Clean App';
			} else if (window.location.hostname.includes('lecocq')) {
				host = 'Lecocq';
			} else if (window.location.hostname.includes('uof')) {
				host = 'uof';
			} else if (window.location.hostname.includes('city-grain')) {
				host = 'CityGrain';
			} else if (window.location.hostname.includes('kiaai')) {
				host = 'kiaai';
			} else if (
				window.location.hostname.includes('admin.ha') ||
				window.location.hostname.includes('ha.admin')
			) {
				host = 'ha';
			} else if (window.location.hostname.includes('alqairawan')) {
				host = 'Alqairawan';
			} else {
				host = window.location.hostname.split(/[ .,-/_]+/)[0];
			}
			let inf = {};
			// eslint-disable-next-line array-callback-return
			payload.contents.map((content) => {
				if (content.name.toLocaleLowerCase() === host.toLocaleLowerCase()) {
					localStorage.setItem('REACT_APP_CLIENT_ID', content._id);
					if (host === 'fablab' || host === 'Ha') {
						localStorage.setItem(
							'REACT_APP_BACKEND_URL',
							'https://api.ha.ae'
							// 'https://services.larsa.io'
						);
					} else if (
						host === 'ead' ||
						window.location.hostname.includes('localhost')
					) {
						localStorage.setItem(
							'REACT_APP_BACKEND_URL',
							'https://api.e-greenlearning.com'
							// 'https://services.larsa.io'
						);
					} else {
						localStorage.setItem(
							'REACT_APP_BACKEND_URL',
							'https://services.larsa.io'
						);
					}
					localStorage.setItem(
						'REACT_APP_SERVICES',
						JSON.stringify([...content.microservices])
					);
					inf = content;
				} else {
					return null;
				}
			});
			return {
				...state,
				info: inf,
			};
		case FORGET_PASSWORD:
			return {
				...state,
			};

		case LOGIN:
			localStorage.setItem('token', payload.token);
			return {
				...state,
			};

		case LOGOUT:
			localStorage.removeItem('token');
			return {
				...state,
				token: null,
				isAuthenticated: false,

				user: {},
			};
		case START_AUTH_RELOAD:
			return {
				...state,
				loading: true,
			};

		case FINISHED_AUTH_RELOAD:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default auth;
