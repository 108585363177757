import {
  READ_E_TAGS,
  READ_ONE_E_TAG,
  CREATE_E_TAG,
  UPDATE_E_TAG,
  DELETE_E_TAG,
  CLEAR_E_TAG,
  START_E_TAGS_RELOAD,
  FINISHED_E_TAGS_RELOAD,
} from '../../actions/types';

const initialState = {
  tags: [],
  tag: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_E_TAGS:
      return {
        ...state,
        tags: [...payload.tags],
        readable: true,
      };
    case READ_ONE_E_TAG:
      return {
        ...state,
        tag: { ...payload.tag },
      };
    case CREATE_E_TAG:
      return {
        ...state,
        tags: [payload, ...state.tags],
      };
    case UPDATE_E_TAG:
      return {
        ...state,
        tags: [
          ...state.tags.map((tag) =>
            tag.id === payload.tag.id ? payload.tag : tag
          ),
        ],
      };
    case DELETE_E_TAG:
      return {
        ...state,
        tags: [...state.tags.filter((tag) => tag.id !== payload.tag.id)],
      };
    case CLEAR_E_TAG:
      return {
        ...state,
        tag: {},
      };
    case START_E_TAGS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_E_TAGS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
