import {
  READ_SCHEMAS,
  READ_ONE_SCHEMA,
  CREATE_SCHEMA,
  UPDATE_SCHEMA,
  DELETE_SCHEMA,
  SCHEMA_ERROR,
  CLEAR_SCHEMA,
  START_SCHEMAS_RELOAD,
  FINISHED_SCHEMAS_RELOAD,
} from '../types';

import { readItemsAsync } from '../equCurd/readItems';
import { readOneItemAsync } from '../equCurd/readOneItem';
import { createItemAsync } from '../equCurd/createItem';
import { updateItemAsync } from '../equCurd/updateItem';
import { deleteItemAsync } from '../equCurd/deleteItem';

export const startSchemasReload = () => (dispatch) => {
  dispatch({ type: START_SCHEMAS_RELOAD });
};

export const finishedSchemasReload = () => (dispatch) => {
  dispatch({ type: FINISHED_SCHEMAS_RELOAD });
};

export const readSchemas = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/schemas/all`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/schemas/all`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/schemas/all`,

    successType: READ_SCHEMAS,
    errorType: SCHEMA_ERROR,
    startReload: startSchemasReload,
    finishedReload: finishedSchemasReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });
// reservation_hall_register
export const readOneSchema = (id) =>
  readOneItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/schemas`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/schemas`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/schemas`,
    successType: READ_ONE_SCHEMA,
    errorType: SCHEMA_ERROR,
    startReload: startSchemasReload,
    finishedReload: finishedSchemasReload,
    id,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const createSchema = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/schemas`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/schemas`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/schemas`,
    successType: CREATE_SCHEMA,
    errorType: SCHEMA_ERROR,
    startReload: startSchemasReload,
    finishedReload: finishedSchemasReload,
    formData,
    title: 'Schema',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const updateSchema = (formData) =>
  updateItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/schemas`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/schemas`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/schemas`,
    successType: UPDATE_SCHEMA,
    errorType: SCHEMA_ERROR,
    startReload: startSchemasReload,
    finishedReload: finishedSchemasReload,
    formData,
    id: formData.id,
    title: 'Schema',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const deleteSchema = (id) =>
  deleteItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/types/schemas`
        : typeof process.env.REACT_APP_BACKEND_URL !== 'undefined'
        ? process.env.REACT_APP_BACKEND_URL + `/types/schemas`
        : localStorage.getItem('REACT_APP_BACKEND_URL') + `/types/schemas`,
    successType: DELETE_SCHEMA,
    errorType: SCHEMA_ERROR,
    startReload: startSchemasReload,
    finishedReload: finishedSchemasReload,
    id: id,
    title: 'Schema',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client': window.location.hostname.includes('lecocq')
        ? '5f9821438d00210019d33809'
        : typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
        ? process.env.REACT_APP_CLIENT_ID
        : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const clearSchema = () => (dispatch) => {
  dispatch({ type: CLEAR_SCHEMA });
};
