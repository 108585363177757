import {
  READ_ALL_PAGES,
  READ_PAGES,
  READ_ONE_PAGE,
  CREATE_PAGE,
  UPDATE_PAGE,
  DELETE_PAGE,
  CLEAR_PAGE,
  START_PAGES_RELOAD,
  FINISHED_PAGES_RELOAD,
} from '../../actions/types';

const initialState = {
  pages: [],
  all_pages: [],
  page: {},
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  error: {},
  loading: false,
  readable: false,
  all_readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ALL_PAGES:
      return {
        ...state,
        all_pages: [...payload.pages],
        all_readable: true,
      };
    case READ_PAGES:
      return {
        ...state,
        pages: [...payload.pages],
        pagination: {
          ...state.pagination,
          totalPages: payload.pagination.totalPages,
          perPage: payload.pagination.perPage,
          totalCount: payload.pagination.totalCount,
        },
        readable: true,
      };
    case READ_ONE_PAGE:
      return {
        ...state,
        page: { ...payload.page },
      };
    case CREATE_PAGE:
      return {
        ...state,
        pages: [payload, ...state.pages],
      };
    case UPDATE_PAGE:
      return {
        ...state,
        pages: [
          ...state.pages.map((page) =>
            page.id === payload.page.id ? payload.page : page
          ),
        ],
      };
    case DELETE_PAGE:
      return {
        ...state,
        pages: [...state.pages.filter((page) => page.id !== payload.page.id)],
      };
    case CLEAR_PAGE:
      return {
        ...state,
        page: {},
      };
    case START_PAGES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_PAGES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
