export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const START_RELOAD = 'START_RELOAD';
export const FINISHED_RELOAD = 'FINISHED_RELOAD';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const UNSET_EDIT_MODE = 'UNSET_EDIT_MODE';
export const SHOW_COVER_MODE = 'SHOW_COVER_MODE';
export const HIDE_COVER_MODE = 'HIDE_COVER_MODE';
export const SET_PROGRESS = 'SET_PROGRESS';
export const UNSET_PROGRESS = 'UNSET_PROGRESS';
export const SET_ACTIVE_LECTURE = 'SET_ACTIVE_LECTURE';
export const SET_IS_MODEL = 'SET_IS_MODEL';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const USER_LOADED = 'USER_LOADED';
export const LOGOUT = 'LOGOUT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SPREAD_LAWS = 'SPREAD_LAWS';
export const START_AUTH_RELOAD = 'START_AUTH_RELOAD';
export const FINISHED_AUTH_RELOAD = 'FINISHED_AUTH_RELOAD';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const EMAIL_EXIST = 'EMAIL_EXIST';
export const EMAIL_ERROR = 'EMAIL_ERROR';

export const READ_ROLES = 'READ_ROLES';
export const READ_ONE_ROLE = 'READ_ONE_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const SEARCH_ROLE_FILTER = 'SEARCH_ROLE_FILTER';
export const ROLE_ERROR = 'ROLE_ERROR';
export const CLEAR_ROLE = 'CLEAR_ROLE';
export const START_ROLES_RELOAD = 'START_ROLES_RELOAD';
export const FINISHED_ROLES_RELOAD = 'FINISHED_ROLES_RELOAD';

export const READ_CAPABILITIES = 'READ_CAPABILITIES';
export const READ_ONE_CAPABILITY = 'READ_ONE_CAPABILITY';
export const CREATE_CAPABILITY = 'CREATE_CAPABILITY';
export const UPDATE_CAPABILITY = 'UPDATE_CAPABILITY';
export const DELETE_CAPABILITY = 'DELETE_CAPABILITY';
export const CAPABILITY_ERROR = 'CAPABILITY_ERROR';
export const CLEAR_CAPABILITY = 'CLEAR_CAPABILITY';
export const START_CAPABILITIES_RELOAD = 'START_CAPABILITIES_RELOAD';
export const FINISHED_CAPABILITIES_RELOAD = 'FINISHED_CAPABILITIES_RELOAD';

export const READ_USERS = 'READ_USERS';
export const READ_ONE_USER = 'READ_ONE_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';
export const START_USERS_RELOAD = 'START_USERS_RELOAD';
export const FINISHED_USERS_RELOAD = 'FINISHED_USERS_RELOAD';

export const READ_E_CATEGORIES = 'READ_E_CATEGORIES';
export const READ_ONE_E_CATEGORY = 'READ_ONE_E_CATEGORY';
export const CREATE_E_CATEGORY = 'CREATE_E_CATEGORY';
export const UPDATE_E_CATEGORY = 'UPDATE_E_CATEGORY';
export const DELETE_E_CATEGORY = 'DELETE_E_CATEGORY';
export const E_CATEGORY_ERROR = 'E_CATEGORY_ERROR';
export const CLEAR_E_CATEGORY = 'CLEAR_E_CATEGORY';
export const START_E_CATEGORIES_RELOAD = 'START_E_CATEGORIES_RELOAD';
export const FINISHED_E_CATEGORIES_RELOAD = 'FINISHED_E_CATEGORIES_RELOAD';

export const READ_T_CATEGORIES = 'READ_T_CATEGORIES';
export const READ_ONE_T_CATEGORY = 'READ_ONE_T_CATEGORY';
export const CREATE_T_CATEGORY = 'CREATE_T_CATEGORY';
export const UPDATE_T_CATEGORY = 'UPDATE_T_CATEGORY';
export const DELETE_T_CATEGORY = 'DELETE_T_CATEGORY';
export const T_CATEGORY_ERROR = 'T_CATEGORY_ERROR';
export const CLEAR_T_CATEGORY = 'CLEAR_T_CATEGORY';
export const START_T_CATEGORIES_RELOAD = 'START_T_CATEGORIES_RELOAD';
export const FINISHED_T_CATEGORIES_RELOAD = 'FINISHED_T_CATEGORIES_RELOAD';

export const READ_B_CATEGORIES = 'READ_B_CATEGORIES';
export const READ_ONE_B_CATEGORY = 'READ_ONE_B_CATEGORY';
export const CREATE_B_CATEGORY = 'CREATE_B_CATEGORY';
export const UPDATE_B_CATEGORY = 'UPDATE_B_CATEGORY';
export const DELETE_B_CATEGORY = 'DELETE_B_CATEGORY';
export const B_CATEGORY_ERROR = 'B_CATEGORY_ERROR';
export const CLEAR_B_CATEGORY = 'CLEAR_B_CATEGORY';
export const START_B_CATEGORIES_RELOAD = 'START_B_CATEGORIES_RELOAD';
export const FINISHED_B_CATEGORIES_RELOAD = 'FINISHED_B_CATEGORIES_RELOAD';

export const READ_E_TAGS = 'READ_E_TAGS';
export const READ_ONE_E_TAG = 'READ_ONE_E_TAG';
export const CREATE_E_TAG = 'CREATE_E_TAG';
export const UPDATE_E_TAG = 'UPDATE_E_TAG';
export const DELETE_E_TAG = 'DELETE_E_TAG';
export const E_TAG_ERROR = 'E_TAG_ERROR';
export const CLEAR_E_TAG = 'CLEAR_E_TAG';
export const START_E_TAGS_RELOAD = 'START_E_TAGS_RELOAD';
export const FINISHED_E_TAGS_RELOAD = 'FINISHED_E_TAGS_RELOAD';

export const READ_B_TAGS = 'READ_B_TAGS';
export const READ_ONE_B_TAG = 'READ_ONE_B_TAG';
export const CREATE_B_TAG = 'CREATE_B_TAG';
export const UPDATE_B_TAG = 'UPDATE_B_TAG';
export const DELETE_B_TAG = 'DELETE_B_TAG';
export const B_TAG_ERROR = 'B_TAG_ERROR';
export const CLEAR_B_TAG = 'CLEAR_B_TAG';
export const START_B_TAGS_RELOAD = 'START_B_TAGS_RELOAD';
export const FINISHED_B_TAGS_RELOAD = 'FINISHED_B_TAGS_RELOAD';

export const READ_T_TAGS = 'READ_T_TAGS';
export const READ_ONE_T_TAG = 'READ_ONE_T_TAG';
export const CREATE_T_TAG = 'CREATE_T_TAG';
export const UPDATE_T_TAG = 'UPDATE_T_TAG';
export const DELETE_T_TAG = 'DELETE_T_TAG';
export const T_TAG_ERROR = 'T_TAG_ERROR';
export const CLEAR_T_TAG = 'CLEAR_T_TAG';
export const START_T_TAGS_RELOAD = 'START_T_TAGS_RELOAD';
export const FINISHED_T_TAGS_RELOAD = 'FINISHED_T_TAGS_RELOAD';

export const READ_FILES = 'READ_FILES';
export const READ_ALL_FILES = 'READ_ALL_FILES';
export const SEARCH_FILES = 'SEARCH_FILES';
export const READ_ONE_FILE = 'READ_ONE_FILE';
export const CREATE_FILE = 'CREATE_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const FILE_ERROR = 'FILE_ERROR';
export const SET_FILE_TYPE = 'SET_FILE_TYPE';
export const SET_FILES_CURRENT_PAGE = 'SET_FILES_CURRENT_PAGE';
export const START_FILES_RELOAD = 'START_FILES_RELOAD';
export const FINISHED_FILES_RELOAD = 'FINISHED_FILES_RELOAD';
export const STREAM_VIDEO = 'STREAM_VIDEO';

export const READ_ALL_PATH = 'READ_ALL_PATH';
export const READ_ONE_PATH = 'READ_ONE_PATH';
export const CREATE_PATH = 'CREATE_PATH';
export const UPDATE_PATH = 'UPDATE_PATH';
export const DELETE_PATH = 'DELETE_PATH';
export const PATH_ERROR = 'PATH_ERROR';
export const CLEAR_PATH = 'CLEAR_PATH';
export const START_PATH_RELOAD = 'START_PATH_RELOAD';
export const FINISHED_PATH_RELOAD = 'FINISHED_PATH_RELOAD';

export const READ_OPTIONS = 'READ_OPTIONS';
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export const FLUSH_ALL_CACHE = 'FLUSH_ALL_CACHE';
export const OPTIONS_ERROR = 'OPTIONS_ERROR';
export const CREATE_LOGO = 'CREATE_LOGO';
export const READ_LOGO = 'READ_LOGO';
export const READ_TEMP_CERT = 'READ_TEMP_CERT';
export const CREATE_PREVIEW_CERT = 'CREATE_PREVIEW_CERT';
export const START_OPTIONS_RELOAD = 'START_OPTIONS_RELOAD';
export const FINISHED_OPTIONS_RELOAD = 'FINISHED_OPTIONS_RELOAD';

export const READ_COURSES = 'READ_COURSES';
export const SEARCH_COURSES = 'SEARCH_COURSES';
export const READ_ONE_COURSE = 'READ_ONE_COURSE';
export const CREATE_COURSE = 'CREATE_COURSE';
export const CREATE_IMAGE_COURSE = 'CREATE_IMAGE_COURSE';
export const READ_IMAGE_COURSE = 'READ_IMAGE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const SAVE_COURSE = 'SAVE_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const SET_COURSES_CURRENT_PAGE = 'SET_COURSES_CURRENT_PAGE';
export const START_COURSES_RELOAD = 'START_COURSES_RELOAD';
export const FINISHED_COURSES_RELOAD = 'FINISHED_COURSES_RELOAD';

export const READ_LECTURES = 'READ_LECTURES';
export const SEARCH_LECTURES = 'SEARCH_LECTURES';
export const READ_ONE_LECTURE = 'READ_ONE_LECTURE';
export const READ_VIDEO_LECTURE = 'READ_VIDEO_LECTURE';
export const CREATE_LECTURE = 'CREATE_LECTURE';
export const CREATE_VIDEO_LECTURE = 'CREATE_VIDEO_LECTURE';
export const SAVE_VIDEO_LECTURE = 'SAVE_VIDEO_LECTURE';
export const CREATE_SLIDES_LECTURE = 'CREATE_SLIDES_LECTURE';
export const START_LECTURES_RELOAD = 'START_LECTURES_RELOAD';
export const FINISHED_LECTURES_RELOAD = 'FINISHED_LECTURES_RELOAD';
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const READ_QUIZ_LECTURE = 'READ_QUIZ_LECTURE';

export const UPDATE_LECTURE = 'UPDATE_LECTURE';
export const CLEAR_VIDEO = 'CLEAR_VIDEO';

export const DELETE_LECTURE = 'DELETE_LECTURE';
export const LECTURE_ERROR = 'LECTURE_ERROR';
export const CLEAR_LECTURE = 'CLEAR_LECTURE';

export const READ_PAGES = 'READ_PAGES';
export const READ_ALL_PAGES = 'READ_ALL_PAGES';
export const READ_ONE_PAGE = 'READ_ONE_PAGE';
export const CREATE_PAGE = 'CREATE_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';
export const PAGE_ERROR = 'PAGE_ERROR';
export const CLEAR_PAGE = 'CLEAR_PAGE';
export const START_PAGES_RELOAD = 'START_PAGES_RELOAD';
export const FINISHED_PAGES_RELOAD = 'FINISHED_PAGES_RELOAD';

export const READ_POSTS = 'READ_POSTS';
export const READ_ALL_POSTS = 'READ_ALL_POSTS';
export const READ_ONE_POST = 'READ_ONE_POST';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const POST_ERROR = 'POST_ERROR';
export const CLEAR_POST = 'CLEAR_POST';
export const START_POSTS_RELOAD = 'START_POSTS_RELOAD';
export const FINISHED_POSTS_RELOAD = 'FINISHED_POSTS_RELOAD';

export const READ_MENUS = 'READ_MENUS';
export const READ_ONE_MENU = 'READ_ONE_MENU';
export const CREATE_MENU = 'CREATE_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';
export const DELETE_MENU = 'DELETE_MENU';
export const MENU_ERROR = 'MENU_ERROR';
export const CLEAR_MENU = 'CLEAR_MENU';
export const START_MENUS_RELOAD = 'START_MENUS_RELOAD';
export const FINISHED_MENUS_RELOAD = 'FINISHED_MENUS_RELOAD';

export const READ_TICKET_TYPES = 'READ_TICKET_TYPES';
export const READ_ALL_TICKET_TYPES = 'READ_ALL_TICKET_TYPES';
export const READ_ONE_TICKET_TYPE = 'READ_ONE_TICKET_TYPE';
export const CREATE_TICKET_TYPE = 'CREATE_TICKET_TYPE';
export const CREATE_CERT_TICKET_TYPE = 'CREATE_CERT_TICKET_TYPE';
export const MAIN_CERT = 'MAIN_CERT';
export const UPDATE_TICKET_TYPE = 'UPDATE_TICKET_TYPE';
export const DELETE_TICKET_TYPE = 'DELETE_TICKET_TYPE';
export const TICKET_TYPE_ERROR = 'TICKET_TYPE_ERROR';
export const CLEAR_TICKET_TYPE = 'CLEAR_TICKET_TYPE';
export const START_TICKET_TYPES_RELOAD = 'START_TICKET_TYPES_RELOAD';
export const FINISHED_TICKET_TYPES_RELOAD = 'FINISHED_TICKET_TYPES_RELOAD';

export const READ_TICKETS = 'READ_TICKETS';
export const READ_ALL_TICKETS = 'READ_ALL_TICKETS';
export const READ_ONE_TICKET = 'READ_ONE_TICKET';
export const CREATE_TICKET = 'CREATE_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const TICKET_ERROR = 'TICKET_ERROR';
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const START_TICKETS_RELOAD = 'START_TICKETS_RELOAD';
export const FINISHED_TICKETS_RELOAD = 'FINISHED_TICKETS_RELOAD';

export const READ_SCHEMAS = 'READ_SCHEMAS';
export const READ_ONE_SCHEMA = 'READ_ONE_SCHEMA';
export const CREATE_SCHEMA = 'CREATE_SCHEMA';
export const UPDATE_SCHEMA = 'UPDATE_SCHEMA';
export const DELETE_SCHEMA = 'DELETE_SCHEMA';
export const SCHEMA_ERROR = 'SCHEMA_ERROR';
export const CLEAR_SCHEMA = 'CLEAR_SCHEMA';
export const START_SCHEMAS_RELOAD = 'START_SCHEMAS_RELOAD';
export const FINISHED_SCHEMAS_RELOAD = 'FINISHED_SCHEMAS_RELOAD';

export const READ_FIELDS = 'READ_FIELDS';
export const READ_ONE_FIELD = 'READ_ONE_FIELD';
export const CREATE_FIELD = 'CREATE_FIELD';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';
export const FIELD_ERROR = 'FIELD_ERROR';
export const CLEAR_FIELD = 'CLEAR_FIELD';
export const START_FIELDS_RELOAD = 'START_FIELDS_RELOAD';
export const FINISHED_FIELDS_RELOAD = 'FINISHED_FIELDS_RELOAD';

export const READ_SERVICES = 'READ_SERVICES';
export const READ_SERVICES_WITHOUT_REDUX = 'READ_SERVICES_WITHOUT_REDUX';
export const READ_ONE_SERVICE = 'READ_ONE_SERVICE';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';
export const START_SERVICES_RELOAD = 'START_SERVICES_RELOAD';
export const FINISHED_SERVICES_RELOAD = 'FINISHED_SERVICES_RELOAD';

export const NEWSLETTER_READ_LISTS = 'NEWSLETTER_READ_LISTS';
export const NEWSLETTER_READ_ONE_LIST = 'NEWSLETTER_READ_ONE_LIST';
export const NEWSLETTER_CREATE_LIST = 'NEWSLETTER_CREATE_LIST';
export const NEWSLETTER_UPDATE_LIST = 'NEWSLETTER_UPDATE_LIST';
export const NEWSLETTER_DELETE_LIST = 'NEWSLETTER_DELETE_LIST';
export const NEWSLETTER_LIST_ERROR = 'NEWSLETTER_LIST_ERROR';
export const NEWSLETTER_CLEAR_LIST = 'NEWSLETTER_CLEAR_LIST';
export const NEWSLETTER_START_LISTS_RELOAD = 'NEWSLETTER_START_LISTS_RELOAD';
export const NEWSLETTER_FINISHED_LISTS_RELOAD =
  'NEWSLETTER_FINISHED_LISTS_RELOAD';

export const NEWSLETTER_READ_FORMS = 'NEWSLETTER_READ_FORMS';
export const NEWSLETTER_READ_ONE_FORM = 'NEWSLETTER_READ_ONE_FORM';
export const NEWSLETTER_CREATE_FORM = 'NEWSLETTER_CREATE_FORM';
export const NEWSLETTER_UPDATE_FORM = 'NEWSLETTER_UPDATE_FORM';
export const NEWSLETTER_DELETE_FORM = 'NEWSLETTER_DELETE_FORM';
export const NEWSLETTER_FORM_ERROR = 'NEWSLETTER_FORM_ERROR';
export const NEWSLETTER_CLEAR_FORM = 'NEWSLETTER_CLEAR_FORM';
export const NEWSLETTER_START_FORMS_RELOAD = 'NEWSLETTER_START_FORMS_RELOAD';
export const NEWSLETTER_FINISHED_FORMS_RELOAD =
  'NEWSLETTER_FINISHED_FORMS_RELOAD';

export const NEWSLETTER_READ_OPTIONS = 'NEWSLETTER_READ_OPTIONS';
export const NEWSLETTER_UPDATE_OPTIONS = 'NEWSLETTER_UPDATE_OPTIONS';
export const NEWSLETTER_OPTIONS_ERROR = 'NEWSLETTER_OPTIONS_ERROR';
export const NEWSLETTER_START_OPTIONS_RELOAD =
  'NEWSLETTER_START_OPTIONS_RELOAD';
export const NEWSLETTER_FINISHED_OPTIONS_RELOAD =
  'NEWSLETTER_FINISHED_OPTIONS_RELOAD';

export const NEWSLETTER_READ_MEMBERS = 'NEWSLETTER_READ_MEMBERS';
export const NEWSLETTER_MEMBERS_ERROR = 'NEWSLETTER_MEMBERS_ERROR';
export const NEWSLETTER_START_MEMBERS_RELOAD =
  'NEWSLETTER_START_MEMBERS_RELOAD';
export const NEWSLETTER_FINISHED_MEMBERS_RELOAD =
  'NEWSLETTER_FINISHED_MEMBERS_RELOAD';
export const NEWSLETTER_CREATE_MEMBER = 'NEWSLETTER_CREATE_MEMBER';
export const NEWSLETTER_DELETE_MEMBER = 'NEWSLETTER_DELETE_MEMBER';

export const NEWSLETTER_READ_CAMPAIGNS = 'NEWSLETTER_READ_CAMPAIGNS';
export const NEWSLETTER_CAMPAIGNS_ERROR = 'NEWSLETTER_CAMPAIGNS_ERROR';
export const NEWSLETTER_START_CAMPAIGNS_RELOAD =
  'NEWSLETTER_START_CAMPAIGNS_RELOAD';
export const NEWSLETTER_FINISHED_CAMPAIGNS_RELOAD =
  'NEWSLETTER_FINISHED_CAMPAIGNS_RELOAD';
export const NEWSLETTER_CLEAR_CAMPAIGN = 'NEWSLETTER_CLEAR_CAMPAIGN';
export const NEWSLETTER_CREATE_CAMPAIGN = 'NEWSLETTER_CREATE_CAMPAIGN';
export const NEWSLETTER_UPDATE_CAMPAIGN = 'NEWSLETTER_UPDATE_CAMPAIGN';
export const NEWSLETTER_DELETE_CAMPAIGN = 'NEWSLETTER_DELETE_CAMPAIGN';
export const NEWSLETTER_READ_ONE_CAMPAIGNS = 'NEWSLETTER_READ_ONE_CAMPAIGNS';

export const READ_ORDERS = 'READ_ORDERS';
export const READ_ONE_ORDER = 'READ_ONE_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const SEARCH_ORDER_FILTER = 'SEARCH_ORDER_FILTER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const START_ORDERS_RELOAD = 'START_ORDERS_RELOAD';
export const FINISHED_ORDERS_RELOAD = 'FINISHED_ORDERS_RELOAD';

export const READ_PAYMENT_OPTIONS = 'READ_PAYMENT_OPTIONS';
export const UPDATE_PAYMENT_OPTIONS = 'UPDATE_PAYMENT_OPTIONS';
export const FLUSH_PAYMENT_CACHE = 'FLUSH_PAYMENT_CACHE';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const READ_PAYMENT_RECONCILIATION = 'READ_PAYMENT_RECONCILIATION';
export const START_PAYMENT_RELOAD = 'START_PAYMENT_RELOAD';
export const FINISHED_PAYMENT_RELOAD = 'FINISHED_PAYMENT_RELOAD';
