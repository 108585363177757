import {
  LOGIN,
  REGISTER,
  USER_LOADED,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  AUTH_ERROR,
  LOGOUT,
  START_AUTH_RELOAD,
  FINISHED_AUTH_RELOAD,
  GET_SUBSCRIPTIONS,
  EMAIL_EXIST,
  EMAIL_ERROR,
} from './types';

import { readItemsAsync } from './equCurd/readItems';
import { createItemAsync } from './equCurd/createItem';

export const startAuthReload = () => (dispatch) => {
  dispatch({ type: START_AUTH_RELOAD });
};

export const finishedAuthReload = () => (dispatch) => {
  dispatch({ type: FINISHED_AUTH_RELOAD });
};

export const readSubscriptions = () =>
  readItemsAsync({
    url:
      typeof process.env.REACT_APP_SUBSCRIPTIONS_URL !== 'undefined'
        ? process.env.REACT_APP_SUBSCRIPTIONS_URL + '/'
        : 'https://manager.larsa.io/api/subscriptions',
    successType: GET_SUBSCRIPTIONS,
    errorType: AUTH_ERROR,
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
  });

export const loadUser = () =>
  readItemsAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/me`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/me'
        : 'https://services.larsa.io/auth/users/me',
    successType: USER_LOADED,
    errorType: AUTH_ERROR,
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const registerUser = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/register`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/register'
        : 'https://services.larsa.io/auth/users/register',
    successType: REGISTER,
    errorType: AUTH_ERROR,
    successMsg: 'Register Succsess',
    errorMsg: 'error something went wrong',
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
    formData,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });
// s
export const loginUser = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/login`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/login'
        : 'https://services.larsa.io/auth/users/login',
    successType: LOGIN,
    errorType: AUTH_ERROR,
    successMsg: 'Login Succsess',
    errorMsg: 'Email or password is incorrect',
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
    formData,
    loginHeader: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const forgotPassword = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/forgotPassword`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/forgotPassword'
        : 'https://services.larsa.io/auth/users/forgotPassword',

    successType: FORGET_PASSWORD,
    errorType: AUTH_ERROR,
    successMsg: 'Check your email to reset your password',
    errorMsg: 'Something went wrong, please try again later',
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
    formData,
    loginHeader: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const resetPassword = (formData, token) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/resetPassword`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/resetPassword'
        : 'https://services.larsa.io/auth/users/resetPassword',

    successType: RESET_PASSWORD,
    errorType: AUTH_ERROR,
    successMsg: 'Password reset was successful',
    errorMsg: 'Something went wrong, please try again later',
    startReload: startAuthReload,
    finishedReload: finishedAuthReload,
    formData,
    loginHeader: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': token,

      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const emailExist = (formData) =>
  createItemAsync({
    url:
      window.location.hostname.includes('fablab') ||
      window.location.hostname.includes('admin.ha') ||
      window.location.hostname.includes('ha.admin')
        ? `https://api.ha.ae/auth/users/emailExist`
        : window.location.hostname.includes('e-green')
        ? 'https://api.e-greenlearning.com/auth/users/emailExist'
        : 'https://services.larsa.io/auth/users/emailExist',
    successType: EMAIL_EXIST,
    errorType: EMAIL_ERROR,
    noReload: true,
    noAlert: true,
    formData,
    title: 'Lecture',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'x-client':
        typeof process.env.REACT_APP_CLIENT_ID !== 'undefined'
          ? process.env.REACT_APP_CLIENT_ID
          : localStorage.getItem('REACT_APP_CLIENT_ID'),
      'x-access-token': localStorage.getItem('token'),
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  });

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

// let d = {
//   create: {
//     status: 'enable',
//     messages: [
//       {
//         status: 'enable',
//         to: [
//           {
//             type: 'emails',
//             value: [
//               'noor.sweyri@gmail.com',
//               'owais@larsa.org',
//               'fablabuae@ha.ae',
//             ],
//           },
//         ],
//         subject: 'New Registration - %schema_title%',
//         body: `
//         <p style='font-size: 16px;color: #383838;'>السادة الكرام</p>
//         <p style='font-size: 14px;color: #383838;'>تحية طيبة وبعد</p>
//         <p style='font-size: 14px;color: #383838;'>نود اشعاركم بأنه تم استلام مشاركة جديدة في مسابقة مبتكرون, والتي تحمل المعلومات التالية</p>

//         <p style='font-size: 14px;line-height:1.5em;margin:30px 0px 0px 0px;'><span style="font-weight: bold;margin:0px 0px 0px 10px;">الاسم</span>:%name%</p>

//         <p style='font-size: 14px;line-height:1.5em;margin:10px 0px 0px 0px;'><span style="font-weight: bold;margin:0px 0px 0px 10px;">المدينة</span>:%city%</p>

//         <p style='font-size: 14px;line-height:1.5em;margin:10px 0px 0px 0px;'><span style="font-weight: bold;margin:0px 0px 0px 10px;">مكان العمل</span>:%workplace%</p>

//         <p style='font-size: 14px;line-height:1.5em;margin:10px 0px 0px 0px;'><span style="font-weight: bold;margin:0px 0px 0px 10px;">اسم المشروع</span>:%projectTitle%</p>

//         <p style='font-size: 14px;line-height:1.5em;margin:10px 0px 0px 0px;'><span style="font-weight: bold;margin:0px 0px 0px 10px;">البريد الالكتروني</span>:%email%</p>

//         <p style='font-size: 16px;color: #383838;margin:20px 0px 0px 0px;'>وشكرا</p>
//         `,
//       },
//       {
//         status: 'enable',
//         to: [
//           {
//             type: 'currentUser',
//           },
//         ],
//         subject: 'Successfully registered in %schema_title%',
//         body: `
//         <p style='font-size: 16px;color: #383838;'>عزيزي %name% المحترم</p>
//         <p style='font-size: 14px;color: #383838;'>تحية طيبة وبعد</p>
//         <p style='font-size: 14px;line-height:1.5em;margin:30px 0px 0px 0px;'>نود اشعاركم بأنه تم استلام مشاركتكم %projectTitle% في مسابقة مبتكرون بنجاح
//         </p>
//         <p style='font-size: 16px;color: #383838;margin:20px 0px 0px 0px;'>وشكرا</p>

//         <p style='font-size: 16px;color: #383838;margin:50px 0px 0px 0px;'>Dear %name%</p>
//         <p style='font-size: 14px;color: #383838;'>Hope you ate doing well,</p>
//         <p style='font-size: 14px;line-height:1.5em;margin:30px 0px 0px 0px;'>We would like to let that your participation %projectTitle% is accepted
//         </p>
//         <p style='font-size: 16px;color: #383838;margin:20px 0px 0px 0px;'>successfully,</p>
//         <p style='font-size: 16px;color: #383838;margin:20px 0px 0px 0px;'>Best regards</p>
//         `,
//       },
//     ],
//   },
//   update: {
//     status: 'disable',
//     messages: [
//       {
//         status: 'enable',
//         to: [
//           {
//             type: 'emails',
//             value: [
//               'noor.sweyri@gmail.com',
//               'owais@larsa.org',
//               'fablabuae@ha.ae',
//             ],
//           },
//         ],
//         subject: 'New registration - %schema_title%',
//         body: 'New user Register to reserve halls. User name is %name%',
//       },
//     ],
//   },
// };
