export const SERVICE_NAME = 'REACT_APP_SURVEY_BACKEND_URL';

export const START_SURVEY_RELOAD = 'START_SURVEY_RELOAD';
export const FINISHED_SURVEY_RELOAD = 'FINISHED_SURVEY_RELOAD';

export const READ_SURVEYS = 'READ_SURVEYS';
export const READ_SINGLE_SURVEY = 'READ_SINGLE_SURVEY';
export const READ_SINGLE_SURVEY_RESULT = 'READ_SINGLE_SURVEY_RESULT';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const CLEAR_SURVEY = 'CLEAR_SURVEYY';
export const SURVEYS_ERROR = 'SURVEYS_ERROR';
