import {
  READ_SCHEMAS,
  READ_ONE_SCHEMA,
  CREATE_SCHEMA,
  UPDATE_SCHEMA,
  DELETE_SCHEMA,
  CLEAR_SCHEMA,
  START_SCHEMAS_RELOAD,
  FINISHED_SCHEMAS_RELOAD,
} from '../../actions/types';

const initialState = {
  schemas: [],
  schema: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SCHEMAS:
      return {
        ...state,
        schemas: [...payload.schemas],
        readable: true,
      };
    case READ_ONE_SCHEMA:
      return {
        ...state,
        schema: { ...payload.schema },
      };
    case CREATE_SCHEMA:
      return {
        ...state,
        schemas: [payload, ...state.schemas],
      };
    case UPDATE_SCHEMA:
      return {
        ...state,
        schemas: [
          ...state.schemas.map((schema) =>
            schema.id === payload.schema.id ? payload.schema : schema
          ),
        ],
      };
    case DELETE_SCHEMA:
      return {
        ...state,
        schemas: [
          ...state.schemas.filter((schema) => schema.id !== payload.schema.id),
        ],
      };
    case CLEAR_SCHEMA:
      return {
        ...state,
        schema: {},
      };

    case START_SCHEMAS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SCHEMAS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
