import {
  READ_APPLICATIONS,
  READ_SINGLE_APPLICATION,
  CREATE_APPLICATION,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
  CLEAR_APPLICATION,
  // APPLICATIONS_ERROR,
  START_APPLICATION_RELOAD,
  FINISHED_APPLICATION_RELOAD,
} from '../../types/tahkeem/applications';
const initialState = {
  applications: [],
  application: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_APPLICATIONS:
      return {
        ...state,
        applications: [...payload.applications],
        readable: true,
      };
    case READ_SINGLE_APPLICATION:
      return {
        ...state,
        application: { ...payload.application },
      };
    case CREATE_APPLICATION:
      return {
        ...state,
        applications: [payload, ...state.applications],
      };
    case UPDATE_APPLICATION:
      return {
        ...state,
        applications: [
          ...state.applications.map((application) =>
            application._id === payload.application._id
              ? payload.application
              : application
          ),
        ],
      };
    case DELETE_APPLICATION:
      return {
        ...state,
        applications: [
          ...state.applications.filter(
            (application) => application._id !== payload.application._id
          ),
        ],
      };
    case CLEAR_APPLICATION:
      return {
        ...state,
        application: {},
      };

    case START_APPLICATION_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_APPLICATION_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
