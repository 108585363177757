export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_JUDGMENT_FILE_RELOAD = 'START_JUDGMENT_FILE_RELOAD';
export const FINISHED_JUDGMENT_FILE_RELOAD = 'FINISHED_JUDGMENT_FILE_RELOAD';

export const READ_JUDGMENT_FILES = 'READ_JUDGMENT_FILES';
export const READ_JUDGMENT_FILES_TREE = 'READ_JUDGMENT_FILES_TREE';
export const READ_SINGLE_JUDGMENT_FILE = 'READ_SINGLE_JUDGMENT_FILE';
export const CREATE_JUDGMENT_FILE = 'CREATE_JUDGMENT_FILE';
export const UPDATE_JUDGMENT_FILE = 'UPDATE_JUDGMENT_FILE';
export const DELETE_JUDGMENT_FILE = 'DELETE_JUDGMENT_FILE';
export const CLEAR_JUDGMENT_FILE = 'CLEAR_JUDGMENT_FILE';
export const JUDGMENT_FILES_ERROR = 'JUDGMENT_FILES_ERROR';
