import {
  READ_ALL_TICKET_TYPES,
  READ_TICKET_TYPES,
  READ_ONE_TICKET_TYPE,
  CREATE_TICKET_TYPE,
  UPDATE_TICKET_TYPE,
  DELETE_TICKET_TYPE,
  CLEAR_TICKET_TYPE,
  START_TICKET_TYPES_RELOAD,
  FINISHED_TICKET_TYPES_RELOAD,
} from '../../actions/types';

const initialState = {
  ticketTypes: [],
  all_ticketTypes: [],
  ticketType: {},
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  error: {},
  loading: false,
  readable: false,
  all_readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ALL_TICKET_TYPES:
      return {
        ...state,
        all_ticketTypes: [...payload.ticketTypes],
        all_readable: true,
      };
    case READ_TICKET_TYPES:
      return {
        ...state,
        ticketTypes: [...payload.ticketTypes],
        pagination: {
          ...state.pagination,
          totalPages: payload.pagination.totalPages,
          perPage: payload.pagination.perPage,
          totalCount: payload.pagination.totalCount,
        },
        readable: true,
      };
    case READ_ONE_TICKET_TYPE:
      return {
        ...state,
        ticketType: { ...payload.ticketType },
      };
    case CREATE_TICKET_TYPE:
      return {
        ...state,
        ticketTypes: [payload, ...state.ticketTypes],
      };
    case UPDATE_TICKET_TYPE:
      return {
        ...state,
        ticketTypes: [
          ...state.ticketTypes.map((ticketType) =>
            ticketType.id === payload.ticketType.id
              ? payload.ticketType
              : ticketType
          ),
        ],
      };
    case DELETE_TICKET_TYPE:
      return {
        ...state,
        ticketTypes: [
          ...state.ticketTypes.filter(
            (ticketType) => ticketType.id !== payload.ticketType.id
          ),
        ],
      };
    case CLEAR_TICKET_TYPE:
      return {
        ...state,
        ticketType: {},
      };
    case START_TICKET_TYPES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_TICKET_TYPES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
