import {
  READ_B_CATEGORIES,
  READ_ONE_B_CATEGORY,
  CREATE_B_CATEGORY,
  UPDATE_B_CATEGORY,
  DELETE_B_CATEGORY,
  CLEAR_B_CATEGORY,
  START_B_CATEGORIES_RELOAD,
  FINISHED_B_CATEGORIES_RELOAD,
} from '../../actions/types';

const initialState = {
  categories: [],
  category: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_B_CATEGORIES:
      return {
        ...state,
        categories: [...payload.categories],
        readable: true,
      };
    case READ_ONE_B_CATEGORY:
      return {
        ...state,
        category: { ...payload.category },
      };
    case CREATE_B_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories],
      };
    case UPDATE_B_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.map((category) =>
            category.id === payload.category.id ? payload.category : category
          ),
        ],
      };
    case DELETE_B_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.filter(
            (category) => category.id !== payload.category.id
          ),
        ],
      };
    case CLEAR_B_CATEGORY:
      return {
        ...state,
        category: {},
      };
    case START_B_CATEGORIES_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_B_CATEGORIES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
