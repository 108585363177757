import {
  READ_ORDERS,
  READ_ONE_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  CLEAR_ORDER,
  START_ORDERS_RELOAD,
  FINISHED_ORDERS_RELOAD,
} from '../../actions/types';

const initialState = {
  orders: [],
  order: {},
  error: {},
  loading: false,
  readable: false,
  readableOne: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ORDERS:
      return {
        ...state,
        orders: [...payload.orders],
        readable: true,
      };
    case READ_ONE_ORDER:
      return {
        ...state,
        order: { ...payload },
        readableOne: true,
      };
    case CREATE_ORDER:
      return {
        ...state,
        orders: [payload, ...state.orders],
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: [
          ...state.orders.map((order) =>
            order.id === payload.order.id ? payload.order : order
          ),
        ],
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: [
          ...state.orders.filter((order) => order.id !== payload.order.id),
        ],
      };
    case CLEAR_ORDER:
      return {
        ...state,
        order: {},
      };

    case START_ORDERS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_ORDERS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
