import {
  READ_JUDGMENT_FILES,
  READ_SINGLE_JUDGMENT_FILE,
  CREATE_JUDGMENT_FILE,
  UPDATE_JUDGMENT_FILE,
  DELETE_JUDGMENT_FILE,
  CLEAR_JUDGMENT_FILE,
  // JUDGMENT_FILES_ERROR,
  START_JUDGMENT_FILE_RELOAD,
  FINISHED_JUDGMENT_FILE_RELOAD,
} from '../../types/tahkeem/judgmentFiles';
const initialState = {
  judgmentFiles: [],
  judgmentFile: {},
  error: {},
  loading: false,
  readable: false,
};

export default function judgmentFiles(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_JUDGMENT_FILES:
      return {
        ...state,
        judgmentFiles: [...payload.judgmentFiles],
        readable: true,
      };

    case READ_SINGLE_JUDGMENT_FILE:
      return {
        ...state,
        judgmentFile: { ...payload.file },
      };
    case CREATE_JUDGMENT_FILE:
      return {
        ...state,
        judgmentFiles: [...state.judgmentFiles, payload.file],
      };
    case UPDATE_JUDGMENT_FILE:
      return {
        ...state,
        judgmentFiles: [
          ...state.judgmentFiles.map(judgmentFile =>
            judgmentFile._id === payload.file._id ? payload.file : judgmentFile
          ),
        ],
      };
    case DELETE_JUDGMENT_FILE:
      return {
        ...state,
        judgmentFiles: [
          ...state.judgmentFiles.filter(
            judgmentFile => judgmentFile._id !== payload.file._id
          ),
        ],
      };
    case CLEAR_JUDGMENT_FILE:
      return {
        ...state,
        judgmentFile: {},
      };

    case START_JUDGMENT_FILE_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_JUDGMENT_FILE_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
