import {
  READ_SCENARIOS,
  READ_SINGLE_SCENARIO,
  CREATE_SCENARIO,
  UPDATE_SCENARIO,
  DELETE_SCENARIO,
  CLEAR_SCENARIO,
  // SCENARIOS_ERROR,
  START_SCENARIO_RELOAD,
  FINISHED_SCENARIO_RELOAD,
} from '../../types/tahkeem/scenarios';

const initialState = {
  scenarios: [],
  scenario: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SCENARIOS:
      return {
        ...state,
        scenarios: [...payload.scenarios],
        readable: true,
      };
    case READ_SINGLE_SCENARIO:
      return {
        ...state,
        scenario: { ...payload.scenario },
      };
    case CREATE_SCENARIO:
      return {
        ...state,
        scenarios: [payload.scenario, ...state.scenarios],
      };
    case UPDATE_SCENARIO:
      return {
        ...state,
        scenarios: [
          ...state.scenarios.map((scenario) =>
            scenario._id === payload.scenario._id ? payload.scenario : scenario
          ),
        ],
      };
    case DELETE_SCENARIO:
      return {
        ...state,
        scenarios: [
          ...state.scenarios.filter(
            (scenario) => scenario._id !== payload.scenario._id
          ),
        ],
      };
    case CLEAR_SCENARIO:
      return {
        ...state,
        scenario: {},
      };

    case START_SCENARIO_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SCENARIO_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
