import {
  READ_ALL_PATH,
  READ_ONE_PATH,
  CREATE_PATH,
  UPDATE_PATH,
  DELETE_PATH,
  PATH_ERROR,
  CLEAR_PATH,
  START_PATH_RELOAD,
  FINISHED_PATH_RELOAD,
} from '../../actions/types';

const initialState = {
  all_path: [],
  path: {},
  loading: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ALL_PATH:
      return {
        ...state,
        all_path: [...payload.learningPaths],
      };
    case CREATE_PATH:
      return {
        ...state,
        all_path: [payload, ...state.all_path],
      };
    case UPDATE_PATH:
      return {
        ...state,
        all_path: [
          ...state.all_path.map((path) =>
            path._id === payload.learningPath.id
              ? {
                  ...payload.learningPath,
                }
              : path
          ),
        ],
        path: { ...payload.learningPath },
      };
    case DELETE_PATH:
      return {
        ...state,
        all_path: [
          ...state.all_path.filter(
            (path) => path._id !== payload.learningPath.id
          ),
        ],
      };

    case READ_ONE_PATH:
      return {
        ...state,
        path: { ...payload.learningPath },
      };
    case CLEAR_PATH:
      return {
        ...state,
        path: {},
      };
    case PATH_ERROR:
      return {
        ...state,
      };
    case START_PATH_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_PATH_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
