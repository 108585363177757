import {
  READ_POSTS,
  READ_ALL_POSTS,
  READ_ONE_POST,
  CREATE_POST,
  UPDATE_POST,
  DELETE_POST,
  CLEAR_POST,
  START_POSTS_RELOAD,
  FINISHED_POSTS_RELOAD,
} from '../../actions/types';

const initialState = {
  posts: [],
  all_posts: [],
  post: {},
  pagination: {
    totalPages: 0,
    perPage: 0,
    totalCount: 0,
  },
  error: {},
  loading: false,
  readable: false,
  all_readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_ALL_POSTS:
      return {
        ...state,
        all_posts: [...payload.posts],
        all_readable: true,
      };
    case READ_POSTS:
      return {
        ...state,
        posts: [...payload.posts],
        pagination: {
          ...state.pagination,
          totalPages: payload.pagination.totalPages,
          perPage: payload.pagination.perPage,
          totalCount: payload.pagination.totalCount,
        },
        readable: true,
      };
    case READ_ONE_POST:
      return {
        ...state,
        post: { ...payload.post },
      };
    case CREATE_POST:
      return {
        ...state,
        posts: [payload, ...state.posts],
      };
    case UPDATE_POST:
      return {
        ...state,
        posts: [
          ...state.posts.map((post) =>
            post.id === payload.post.id ? payload.post : post
          ),
        ],
      };
    case DELETE_POST:
      return {
        ...state,
        posts: [...state.posts.filter((post) => post.id !== payload.post.id)],
      };
    case CLEAR_POST:
      return {
        ...state,
        post: {},
      };
    case START_POSTS_RELOAD:
      return {
        ...state,
        loading: true,
      };

    case FINISHED_POSTS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
