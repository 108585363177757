export const SERVICE_NAME = 'REACT_APP_TAHKEEM_BACKEND_URL';

export const START_FORM_RELOAD = 'START_FORM_RELOAD';
export const FINISHED_FORM_RELOAD = 'FINISHED_FORM_RELOAD';

export const READ_FORMS = 'READ_FORMS';
export const READ_SINGLE_FORM = 'READ_SINGLE_FORM';
export const CREATE_FORM = 'CREATE_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const CLEAR_FORM = 'CLEAR_FORM';
export const FORMS_ERROR = 'FORMS_ERROR';
