import {
  NEWSLETTER_READ_FORMS,
  NEWSLETTER_READ_ONE_FORM,
  NEWSLETTER_CREATE_FORM,
  NEWSLETTER_UPDATE_FORM,
  NEWSLETTER_DELETE_FORM,
  NEWSLETTER_CLEAR_FORM,
  NEWSLETTER_START_FORMS_RELOAD,
  NEWSLETTER_FINISHED_FORMS_RELOAD,
} from '../../actions/types';

const initialState = {
  forms: [],
  form: [],
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEWSLETTER_READ_FORMS:
      return {
        ...state,
        forms: [...payload.forms],
        readable: true,
      };
    case NEWSLETTER_READ_ONE_FORM:
      return {
        ...state,
        form: { ...payload.form },
      };
    case NEWSLETTER_CREATE_FORM:
      return {
        ...state,
        forms: [...state.forms, payload],
      };
    case NEWSLETTER_UPDATE_FORM:
      return {
        ...state,
        forms: [
          ...state.forms.map((form) => {
            return form.id === payload.form.id ? payload.form : form;
          }),
        ],
      };
    case NEWSLETTER_DELETE_FORM:
      return {
        ...state,
        forms: [
          ...state.forms.filter((form) => {
            return form.id !== payload.form.id;
          }),
        ],
      };
    case NEWSLETTER_CLEAR_FORM:
      return {
        ...state,
        form: {},
      };
    case NEWSLETTER_START_FORMS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER_FINISHED_FORMS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
