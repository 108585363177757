import {
  READ_SURVEYS,
  READ_SINGLE_SURVEY,
  CREATE_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  CLEAR_SURVEY,
  // SURVEYS_ERROR,
  START_SURVEY_RELOAD,
  FINISHED_SURVEY_RELOAD,
} from '../../types/surveys/surveys';
const initialState = {
  surveys: [],
  survey: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_SURVEYS:
      return {
        ...state,
        surveys: [...payload.surveys],
        readable: true,
      };
    case READ_SINGLE_SURVEY:
      return {
        ...state,
        survey: { ...payload.survey },
      };
    case CREATE_SURVEY:
      return {
        ...state,
        surveys: [payload, ...state.surveys],
      };
    case UPDATE_SURVEY:
      return {
        ...state,
        surveys: [
          ...state.surveys.map((survey) =>
            survey._id === payload.survey._id ? payload.survey : survey
          ),
        ],
      };
    case DELETE_SURVEY:
      return {
        ...state,
        surveys: [
          ...state.surveys.filter(
            (survey) => survey._id !== payload.survey._id
          ),
        ],
      };
    case CLEAR_SURVEY:
      return {
        ...state,
        survey: {},
      };

    case START_SURVEY_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SURVEY_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
