import React from 'react';

function Spinner() {
  return (
    <div className="absolute top-0 left-0 right-0 flex items-center justify-center w-full h-80">
      <div className="loader"></div>
    </div>
  );
}

export default Spinner;
