import {
  NEWSLETTER_READ_OPTIONS,
  NEWSLETTER_UPDATE_OPTIONS,
  NEWSLETTER_START_OPTIONS_RELOAD,
  NEWSLETTER_FINISHED_OPTIONS_RELOAD,
} from '../../actions/types';

const initialState = {
  options: [],
  loading: false,
  readable: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSLETTER_READ_OPTIONS:
      return { ...state, options: payload.options, readable: true };
    case NEWSLETTER_START_OPTIONS_RELOAD:
      return { ...state, loading: true };
    case NEWSLETTER_FINISHED_OPTIONS_RELOAD:
      return { ...state, loading: false };
    case NEWSLETTER_UPDATE_OPTIONS:
      return state;
    default:
      return state;
  }
};
