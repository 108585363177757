import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

const Alert = ({ alerts }) => {
  alerts !== null &&
    alerts.length !== 0 &&
    toast[alerts[0].alertType ? alerts[0].alertType : 'info'](alerts[0].msg, {
      containerId: 'a'
    });

  return (
    <ToastContainer
      position="top-right"
      autoClose={2500}
      hideProgressBar
      newestOnTop
      closeOnClick
      transition={Slide}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
      enableMultiContainer
      containerId={'a'}
    />
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.appReducer.alert
});

export default connect(mapStateToProps)(Alert);
