import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const checkRoles = (userRoles, allowedRoles) => {
  if (allowedRoles.length === 0) {
    // return true;
  }
  // console.log(userRoles.some(role => allowedRoles.includes(role.name)));
  return userRoles.some(role => allowedRoles.includes(role.name));
};

const ACL = ({
  allowedRoles,
  children,
  renderNoAccess,
  authReducer: { user },
  rolesReducer: { roles },
}) => {
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    if (roles.length > 0) {
      // setUserRoles(roles.filter((r) => r.id === user.roles[0]));
      setUserRoles(roles.filter(r => user.roles.includes(r.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, user]);
  // console.log({ userRoles });
  const permitted = checkRoles(userRoles, allowedRoles);
  if (permitted && roles.length > 0 && user.roles.length > 0) {
    return children;
  }
  return renderNoAccess();
};

ACL.defaultProps = {
  allowedRoles: [],
  // userRoles: rolesReducer.roles.filter(),
  renderNoAccess: () => null,
};

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  rolesReducer: state.rolesReducer,
  capabilitiesReducer: state.capabilitiesReducer,
});

export default connect(mapStateToProps, null)(ACL);
