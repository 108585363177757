import { combineReducers } from 'redux';
import users from './users';
import roles from './roles';
import capabilities from './capabilities';
import eCategories from './eLearning/eCategories';
import eTags from './eLearning/eTags';
import bCategories from './blog/bCategories';
import bTags from './blog/bTags';
import files from './files';
import app from './app';
import auth from './auth';
import options from './options';
import courses from './eLearning/courses';
import path from './eLearning/path';
import pages from './blog/pages';
import posts from './blog/posts';
import menus from './blog/menus';
import ticketTypes from './ticketing/ticketTypes';
import tickets from './ticketing/tickets';
import wCategories from './ticketing/tCategories';
import tTags from './ticketing/tTags';

import schemas from './typing/schemas';
import services from './typing/services';
// import lectures from './lectures';

import lists from './newsletter/lists';
import listsForms from './newsletter/forms';
import newsletterOptions from './newsletter/options';
import newsletterMembers from './newsletter/members';
import newsletterCampaigns from './newsletter/campaigns';
import orders from './orders/orders';
import payment from './payment/payment';
import forms from './tahkeem/forms';
import tCategories from './tahkeem/tCategories';
import judgments from './tahkeem/judgments';
import assessments from './tahkeem/assessments';
import scenarios from './tahkeem/scenarios';
import seasons from './tahkeem/seasons';
import judgmentFiles from './tahkeem/judgmentFiles';
import applications from './tahkeem/applications';
import cycles from './tahkeem/cycles';
import surveys from './surveys/surveys';

export default combineReducers({
  usersReducer: users,
  rolesReducer: roles,
  capabilitiesReducer: capabilities,
  eCategoriesReducer: eCategories,
  eTagsReducer: eTags,
  bCategoriesReducer: bCategories,
  bTagsReducer: bTags,
  appReducer: app,
  authReducer: auth,
  filesReducer: files,
  optionsReducer: options,
  coursesReducer: courses,
  pathReducer: path,
  pagesReducer: pages,
  postsReducer: posts,
  menusReducer: menus,
  ticketTypesReducer: ticketTypes,
  ticketsReducer: tickets,
  tTagsReducer: tTags,
  wCategoriesReducer: wCategories,
  schemasReducer: schemas,
  servicesReducer: services,
  ordersReducer: orders,
  listsReducer: lists,
  listsFormsReducer: listsForms,
  newsletterOptionsReducer: newsletterOptions,
  newsletterMembersReducer: newsletterMembers,
  newsletterCampaignsReducer: newsletterCampaigns,
  paymentReducer: payment,
  formsReducer: forms,
  tCategoriesReducer: tCategories,
  judgmentsReducer: judgments,
  assessmentsReducer: assessments,
  scenariosReducer: scenarios,
  seasonsReducer: seasons,
  judgmentFilesReducer: judgmentFiles,
  applicationsReducer: applications,
  cyclesReducer: cycles,
  surveysReducer: surveys,
});
