import {
  READ_FORMS,
  READ_SINGLE_FORM,
  CREATE_FORM,
  UPDATE_FORM,
  DELETE_FORM,
  CLEAR_FORM,
  // FORMS_ERROR,
  START_FORM_RELOAD,
  FINISHED_FORM_RELOAD,
} from '../../types/tahkeem/forms';
const initialState = {
  forms: [],
  form: {},
  error: {},
  loading: false,
  readable: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_FORMS:
      return {
        ...state,
        forms: [...payload.forms],
        readable: true,
      };
    case READ_SINGLE_FORM:
      return {
        ...state,
        form: { ...payload.form },
      };
    case CREATE_FORM:
      return {
        ...state,
        forms: [payload, ...state.forms],
      };
    case UPDATE_FORM:
      return {
        ...state,
        forms: [
          ...state.forms.map((form) =>
            form._id === payload.form._id ? payload.form : form
          ),
        ],
      };
    case DELETE_FORM:
      return {
        ...state,
        forms: [...state.forms.filter((form) => form._id !== payload.form._id)],
      };
    case CLEAR_FORM:
      return {
        ...state,
        form: {},
      };

    case START_FORM_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_FORM_RELOAD:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
