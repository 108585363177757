import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { AiOutlineSetting } from 'react-icons/ai';
import { MdEmail, MdCached } from 'react-icons/md';
import { CgIfDesign } from 'react-icons/cg';
import { FaUserCircle, FaRegNewspaper } from 'react-icons/fa';
import { FiChevronDown, FiLogIn, FiInfo } from 'react-icons/fi';
import { IoIosOptions } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import ar from '../../assets/ar.png';
import en from '../../assets/en.png';
import { setHideSidebar } from '../../actions/app';
import OutsideAlerter from '../OutsideAlerter';
import { useSpring, animated } from 'react-spring';
import ACL from '../../acl/ACL';
import { hasSubscription } from '../../helpers/hasSubscription';

const Navbar = ({ setHideSidebar, appReducer: { hideSidebar } }) => {
  const [subMenuLan, setSubMenuLan] = useState(false);
  const [subMenuOpt, setSubMenuOpt] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(true);

  const [navbarLang, setNavbarLang] = useState({
    src: en,
    text: '',
  });
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  useEffect(() => {
    if (localStorage.getItem('i18nextLng') === 'en') {
      setNavbarLang({
        ...navbarLang,
        src: en,
      });
    } else {
      setNavbarLang({
        ...navbarLang,
        src: ar,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { i18n } = useTranslation();
  const handleClick = lang => {
    i18n.changeLanguage(lang);
    setNavbarLang({
      ...navbarLang,
      src: lang === 'en' ? en : ar,
    });
    setSubMenuLan(false);
  };

  const onLanguageChange = () => {
    setSubMenuLan(!subMenuLan);
    setSubMenuOpt(false);
  };
  const onOptionChange = () => {
    setSubMenuOpt(!subMenuOpt);
    setSubMenuLan(false);
  };

  return (
    <nav
      className="z-40 flex self-end h-18"
      style={{
        width: 'calc(100% - 16rem)',
        backgroundColor: window.location.hostname.includes('lecocq')
          ? '#fafafa'
          : '#0c64f7',
      }}>
      <div
        className="flex flex-wrap items-center justify-between w-full px-5 border-b border-gray-200 rounded-t-lg bg-gray-p"
        style={{
          borderTopLeftRadius: '30px',
          borderTopRightRadius: '30px',
        }}>
        <ToastContainer
          position="top-center"
          autoClose={false}
          newestOnTop
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnVisibilityChange
          draggable={false}
          enableMultiContainer
          containerId={'d'}
        />

        <div className="px-6  md:hidden sm:flex">
          <button
            className="flex items-center px-3 py-2 text-blue-400 border border-blue-400 rounded hover:text-blue-500 hover:border-blue-500"
            onClick={() => setMobileMenu(true)}>
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          {mobileMenu && (
            <OutsideAlerter e={() => setMobileMenu(false)}>
              <div className="absolute z-10 flex flex-col py-2 bg-white border border-gray-300 rounded top-16 right-3">
                {/* <NavLink
                  to="/profile"
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  onClick={() => setMobileMenu(false)}
                >
                  <p className="px-2 text-sm text-gray-700">profile</p>
                  <div className="px-2">
                    <FaUserCircle size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink> */}
                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/general"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">General</p>
                  <div className="px-2">
                    <IoIosOptions size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>
                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/email"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">Email</p>
                  <div className="px-2">
                    <MdEmail size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>
                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/login-providers"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">Login Providers</p>
                  <div className="px-2">
                    <FiLogIn size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>

                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/certificates"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">Certificates</p>
                  <div className="px-2">
                    <FaRegNewspaper size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>

                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/license"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">License</p>
                  <div className="px-2">
                    <FiInfo size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>
                <NavLink
                  className="flex items-center justify-between py-2 hover:bg-gray-100"
                  to="/options/flush-all-cache"
                  onClick={() => setMobileMenu(false)}>
                  <p className="px-2 text-sm text-gray-700">Cache</p>
                  <div className="px-2">
                    <MdCached size="1.5em" className="text-gray-600" />
                  </div>
                </NavLink>
              </div>
            </OutsideAlerter>
          )}
        </div>
        {/* <animated.div
        onClick={() => setHideSidebar()}
        className="absolute top-0 left-0 flex items-center justify-center w-32 h-16 px-2 cursor-pointer"
        style={fade}
      >
        {hideSidebar ? (
          <FiMenu className="text-gray-600 hover:text-blue-500" size={30} />
        ) : (
          <FiX className="text-gray-600 hover:text-blue-500" size={30} />
        )}
      </animated.div> */}
        <animated.div
          className="flex items-center justify-end h-full px-6  md:flex"
          style={fade}>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col items-start justify-center cursor-pointer">
              <div
                onClick={() => onLanguageChange()}
                className="flex items-center justify-between h-16 hover:bg-gray-100">
                <div className="px-2">
                  <img className="w-6" src={navbarLang.src} alt="en lang" />
                </div>
                <p className="flex items-center justify-center px-2 pr-8">
                  <FiChevronDown className="mx-2" />
                </p>
              </div>

              {subMenuLan && (
                <OutsideAlerter e={() => setSubMenuLan(false)}>
                  <div className="absolute z-10 flex flex-col py-2 bg-white border border-gray-300 rounded  top-16">
                    <div
                      onClick={() => handleClick('ar')}
                      className="flex items-center justify-between py-2 pr-6 cursor-pointer hover:bg-gray-100">
                      <div className="px-2">
                        <img className="w-6" src={ar} alt="en lang" />
                      </div>
                      <p className="px-2 pr-8">Arabic</p>
                    </div>
                    <div
                      onClick={() => handleClick('en')}
                      className="flex items-center justify-between py-2 pr-6 cursor-pointer hover:bg-gray-100">
                      <div className="px-2">
                        <img className="w-6 " src={en} alt="ar lang" />
                      </div>
                      <p className="px-2 pr-8">English</p>
                    </div>
                  </div>
                </OutsideAlerter>
              )}
            </div>

            <NavLink to="/profile">
              <FaUserCircle
                size="1.5em"
                className="mx-4 text-gray-700 hover:text-gray-600"
              />
            </NavLink>
            <ACL
              allowedRoles={['super', 'administrator', 'editor']}
              renderNoAccess={() => <></>}>
              <div className="flex flex-col items-start justify-center cursor-pointer">
                <div
                  onClick={() => onOptionChange()}
                  className="flex items-center justify-between">
                  <AiOutlineSetting
                    size="1.5em"
                    className="text-gray-700 hover:text-gray-600"
                  />
                </div>

                {subMenuOpt && (
                  <OutsideAlerter e={() => setSubMenuOpt(false)}>
                    <div className="absolute right-0 z-30 flex flex-col p-2 mx-4 bg-white border border-gray-300 rounded  top-16">
                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/general"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">General</p>
                        <div className="px-2">
                          <IoIosOptions
                            size="1.5em"
                            className="text-gray-600"
                          />
                        </div>
                      </NavLink>
                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/email"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">Email</p>
                        <div className="px-2">
                          <MdEmail size="1.5em" className="text-gray-600" />
                        </div>
                      </NavLink>
                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/login-providers"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">
                          Login Providers
                        </p>
                        <div className="px-2">
                          <FiLogIn size="1.5em" className="text-gray-600" />
                        </div>
                      </NavLink>
                      {hasSubscription('el') && (
                        <NavLink
                          className="flex items-center justify-between py-2 hover:bg-gray-100"
                          to="/options/certificates"
                          onClick={() => setSubMenuOpt(false)}>
                          <p className="px-2 text-sm text-gray-700">
                            Certificates
                          </p>
                          <div className="px-2">
                            <FaRegNewspaper
                              size="1.5em"
                              className="text-gray-600"
                            />
                          </div>
                        </NavLink>
                      )}

                      {hasSubscription('ticketing') && (
                        <NavLink
                          className="flex items-center justify-between py-2 hover:bg-gray-100"
                          to="/options/ticket_certificates"
                          onClick={() => setSubMenuOpt(false)}>
                          <p className="px-2 text-sm text-gray-700">
                            Ticket Certificates
                          </p>
                          <div className="px-2">
                            <FaRegNewspaper
                              size="1.5em"
                              className="text-gray-600"
                            />
                          </div>
                        </NavLink>
                      )}

                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/license"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">License</p>
                        <div className="px-2">
                          <FiInfo size="1.5em" className="text-gray-600" />
                        </div>
                      </NavLink>
                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/flush-all-cache"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">Cache</p>
                        <div className="px-2">
                          <MdCached size="1.5em" className="text-gray-600" />
                        </div>
                      </NavLink>
                      <NavLink
                        className="flex items-center justify-between py-2 hover:bg-gray-100"
                        to="/options/theme"
                        onClick={() => setSubMenuOpt(false)}>
                        <p className="px-2 text-sm text-gray-700">Theme</p>
                        <div className="px-2">
                          <CgIfDesign size="1.5em" className="text-gray-600" />
                        </div>
                      </NavLink>
                    </div>
                  </OutsideAlerter>
                )}
              </div>
            </ACL>
          </div>
        </animated.div>
      </div>
    </nav>
  );
};
const mapStateToProps = state => ({
  appReducer: state.appReducer,
});

export default connect(mapStateToProps, { setHideSidebar })(Navbar);
